.galeria-kategoria-section {
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    @media (max-width: 811px) {
        padding: 0 20px;
    } 
    .galeria-title {
        span {
            color: $sotet;
            font-size: var(--font-medium-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium-large);
        }
    }
    .galeria-row {
        padding-top: 49.79px;
        margin: 0 -12px;
        .galeria-col {
            padding: 0 12px 24px 12px;

          .blurhash-image-container {
            width: 100%;

            img {
              aspect-ratio: 1 / 1;
              width: 100% !important;
              height: auto !important;
            }
          }
            .program-card-div {
                //max-width: 297px;
                width: 100%;
                height: 395px;
                flex-shrink: 0; 
                background: $feher;
                box-shadow: 4px 4px 11px 0px rgba(0, 0, 0, 0.14);

                .image-div {
                    img {
                        width: 100%;
                        height: 297px;
                        object-fit: cover;
                    }
                }
                .datum-div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 24px;
                    span {
                        color: #000;
                        text-align: center;
                        font-size: var(--font-normal);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-normal);
                    }
                }
                .text-div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 98px;
                    span {
                        color: #000;
                        text-align: center;
                        font-size: var(--font-normal);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-normal);
                    }
                }
            }
            .gallery-image {
                width: 190px;
                height: 190px;
                flex-shrink: 0; 
                object-fit: cover;
                @media (max-width: 811px) {
                    width: 100%;
                    
                } 
            }
        }
    }
    .loadmore-div {
        display: flex;
        align-items: center;
        justify-content:space-between;
        padding-right: 12px;
        button {
            background: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
            span {
                color: $elsodleges;
                font-size: var(--font-normal);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-normal);
                text-decoration-line: underline; 
            }
        }
    }
    
}