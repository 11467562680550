.kapcsolat-map-section {
    background: $feher;
    position: relative;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    &.tb-kartya {
        .kapcsolat-map-div .one-col-div .up-div p.title-text {
            font-size: 18px;
        }
    }

    &.kapcsolat-sect {
        padding-bottom: 64px;
    }

    h4 {
        color: #000;
        font-style: italic;
        font-weight: 600 !important;
        margin-bottom: var(--within-page-padding);
        font-size: var(--font-medium);
        line-height: var(--line-height-medium);
    }

    .teremberles-grid {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;

        .teremberles-box {
            width: calc(50% - 12px);
        }
    }

    .kapcsolat-map-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @media (max-width: 811px) {
            flex-direction: column;
            padding-top: 0 !important;
        }

        .primary-button {
            background-color: #211551;
            border: none;
            color: white;
            font-size: var(--font-small);
            font-style: italic;
            font-weight: 700;
            line-height: normal;
            margin-top: 15px;
            margin-bottom: 38px;
            padding: 10px 20px 10px 24px;
            text-transform: uppercase;
            width: min-content;
            white-space: nowrap;
        }

        .left-col-div {
            display: table-cell;
            width: 50%;

            @media (max-width: 811px) {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 0;
                width: 100%;
            }

            .up-div {
                display: flex;
                flex-direction: column;
                margin-bottom: 115px;
                padding-right: 30px;

                @media (max-width: 811px) {
                    margin-bottom: 0;
                }

                h3 {
                    color: #000;
                    font-size: var(--font-medium-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium-large);
                    margin-bottom: var(--within-page-padding);
                }

                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-bottom: 10px;
                }
                a {
                    color: $kiemelt;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                }
                p {
                    overflow-wrap: break-word;
                }
                
            }
            .down-div {
                display: flex;
                flex-direction: column;
                p {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                    margin-top: 10px;
                }
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                }
            }
        }
        .right-col-div {
            display: table-cell;
            width: 50%;
            padding-top: calc(19px + var(--within-page-padding));

            @media (max-width: 811px) {
                width: 100%;
                padding: 20px 20px 0 20px;
            }

            .map-keret {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                flex-shrink: 0; 
                border-radius: 4px;
                background: $feher;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);

                @media (max-width: 811px) {
                    aspect-ratio: 1 / 1;
                    height: unset;
                    width: 100%;
                }
            }
        }
        .one-col-div {
            display: table-cell;
            width: calc(100vw - (100vw - 1008px) / 2 - 40vw);
            @media (max-width: 1200px) {
                width: 75%;
            }
            @media (max-width: 811px) {
                width: 100%;
                padding: 0 20px;
            }
            .up-div {
                display: flex;
                flex-direction: column;
                padding-right: 24px;

                h3 {
                    color: #000;
                    font-size: var(--font-medium-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium-large);
                    margin-bottom: var(--within-page-padding);

                    @media (max-width: 811px) {
                      margin-bottom: 16px;
                    }
                }

                p {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-top: 0px;
                    overflow-wrap: break-word;

                    &.title-text {
                        color: $sotet;
                        font-size: var(--font-medium);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium);
                    }

                    &:last-child {
                        margin-bottom: var(--within-page-padding);
                    }
                }
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-bottom: 10px;

                    &.button-text {
                        font-size: var(--font-small);
                        line-height: var(--line-height-small);
                    }

                    &.kiemelt-text {
                        font-size: var(--font-normal);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-normal);
                        margin-bottom: 0;
                    }
                   
                }
                ol {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                    li {
                        display: list-item;
                    }
                }
                
                a {
                    color: $kiemelt;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                }
                .teremberles-first {
                    width: 100%;
                    margin-bottom: var(--within-page-padding);
                }

                .tbkartya-image {
                    width: 100%;
                    margin-bottom: var(--within-page-padding);
                }

                .loadmore-div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: var(--within-page-padding);
                    width: 100%;

                    button {
                        .button-text {
                            text-transform: initial; 
                            color: $vilagos;
                            margin: 0;
                        }
                        &:hover {
                            background: $kiemelt; 
                            transition: 0.6s ease-in-out;
                        }
                    }
                    svg {
                        margin-top: -4px;
                        margin-left: 4px;
                    }
                }
                .documents {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    .table th {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                        padding-left: 10px;
                        width: 50px;
                    }
                    .table td {
                        padding-left: 0;
                        .documents-download {
                            cursor: pointer;
                            width: 100%;
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-start;
                            
                            a {
                                color: var(--Szveg, #404040);
                                font-family: Urbanist;
                                font-size: var(--font-normal);
                                font-style: normal;
                                font-weight: 200 !important;
                                line-height: var(--line-height-normal);
                                &:hover {
                                    color: #F0B323;
                                }
                            }
                        }
                    }
                   
                }

                .terem-card-div {
                    width: 100%;
                    cursor: pointer;
                    .image-div {
                        img {
                            width: 100%;
                        }
                        
                    }
                    &:hover {
                        .image-div {
                            filter:  contrast(50%);
                        }
                    }
                    &.katalogus {
                        .title-div {
                            background-color: #F0B323;
                            
                        }
                    }
                    &.agora {
                        .title-div {
                            background-color: #F0B323;
                        }
                    }
                    &.banyasz {
                        .title-div {
                            background-color: #FA8934;
                        }
                    }
                    &.ja {
                        .title-div {
                            background-color: #54A8DE;
                        }
                    }
                    &.puskin {
                        .title-div {
                            background-color: #AFCA21;
                        }
                    }
                    &.szi {
                        .title-div {
                            background-color: #9A96F7;
                        }
                    }
                    .title-div {
                        width: 100%;
                        height: 102px;
                        flex-shrink: 0; 
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        p {
                            color: $sotet;
                            text-align: center;
                            font-size: var(--font-medium);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-medium);
                            max-width: 277px;
                            margin-bottom: 0;
                        }
                       
                    }
                    &:hover {
                        .title-div {
                            background-color: $elsodleges;
                            p {
                                color: $feher;
                            }
                        }
                    }
                }
                .palyazatok-card-div {
                    display: flex;
                    height: 177px;
                    
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0; 
                    cursor: pointer;
                    border: 2px solid #D4D4D4;
                    background: #FFF; 
                    padding: 0 10px;

                    p {
                        margin: 0;
                        color: #000;
                        text-align: center;
                        font-size: var(--font-medium);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium);
                    }
                    &:hover {
                        p {
                            color: #F0B323; 
                        }
                    }
                }
                .helyszin-div {
                    .title {
                        margin-top: 20px;
                        height: 38px; 
                        span {
                            color: $sotet;
                            font-size: var(--font-normal);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-normal); 
                        }
                    }
                    .address {
                        span {
                            color: $szoveg;
                            font-size: var(--font-normal);
                            font-style: normal;
                            font-weight: 200 !important;
                            line-height: var(--line-height-normal); 
                        }
                    }
                    .program-maps {
                        width: 100%;
                        padding: 25px 0 50px 0;
                        p {
                            color: $szoveg;
                            font-size: var(--font-normal);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-normal);
                            margin-bottom: 10px;
                        }
                        span {
                            color: $szoveg;
                            font-size: var(--font-normal);
                            font-style: normal;
                            font-weight: 200 !important;
                            line-height: var(--line-height-normal);
                            
                        }
                        .map-keret {
                            margin-top: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 441px; 
                            flex-shrink: 0; 
                            border-radius: 4px;
                            background: $feher;
                            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04); 
                        }
                    }
                }
                .gallery {
                    max-width: 811px;
                    padding: 0;
                    position: relative;

                    .gallery-title {
                        margin-top: 20px;
                        height: 38px; 
                        span {
                            color: $sotet;
                            font-size: var(--font-normal);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-normal); 
                        }
                        
                    }
                    .image-items {
                        .gallery-image-one {
                            max-width: 100%;
                        }
                    }
                }
                .video {
                    margin-top: 60px;

                    iframe {
                        aspect-ratio: 811 / 456;
                        height: auto;
                        width: 100%;
                    }
                }
                .image-div {
                    width: 100%;
                    //height: 394px;
                    background: rgba(33, 21, 81, 0.90); 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        height: 227px;
                        @media (max-width: 810px) {
                            width: 100%;
                            height: unset;
                        }
                    }
                }
            }
            .nepmesepont {
                display: flex;
                flex-direction: column;
                margin-bottom: 115px;
                @media (max-width: 811px) {
                    margin-bottom: 25px;
                } 
                h3 {
                    color: #000;
                    font-size: var(--font--medium-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium-large);
                    margin-bottom: var(--within-page-padding);
                }
                .title {
                    color: $szoveg;
                    font-size: var(--font--medium);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height--medium);
                    margin-bottom: 25px;
                }
                .description {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-bottom: 10px;
                }
            }
            .down-div {
                display: flex;
                flex-direction: column;
                p {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal); 
                    margin-top: 10px;
                }
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                }
                a {
                    color: $kiemelt;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                }
            }
        }
    }
    .background-div {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        background-color: $vilagos;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        padding-left: 6%;
        padding-top: 111px;
        @media (max-width: 1200px) {
            width: 25%;
        }
        @media (max-width: 811px) {
          display: none;
        }
        .awards {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            span {
                color: $szoveg;
                font-size: var(--font-medium);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-medium);
                margin-bottom: 50px;
            }
            .awards-img {
                width: 112.238px;
                //height: 122.842px;
                flex-shrink: 0; 
                margin-bottom: 60px;
            }
        }
        @media (max-width: 1980px) {
            //width: 42%;
        }
    }
}
.nepmese-map-section {
    background: $feher;
    position: relative;
    .nepmese-container {
        position: relative;
        .kapcsolat-map-div {
            width: 70%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            @media (max-width: 811px) {
                width: 100%;
            } 
            .one-col-div {
                padding: 61px 0 0 15px;
                padding-bottom: 90px;
                @media (max-width: 1200px) {
                    width: 75%;
                }
                @media (max-width: 811px) {
                    width: 100%;
                    padding: 61px 20px 0 20px;
                } 
                .navigate-div {
                    margin-top: -29px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 29px;
                    button {
                        color: $szoveg;
                        font-size: var(--font-small);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-small);
                        cursor: pointer;
                        background: transparent;
                        border: none; 
                        box-shadow: none;
                        &:hover {
                            color: $kiemelt;
                        }
                    }
                    img {
                        margin: 0 10px;
                    }
                }
                .nepmesepont {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 115px;
                    @media (max-width: 811px) {
                        margin-bottom: 25px;
                    } 
                    h3 {
                        color: #000;
                        font-size: var(--font-medium-large);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium-large);
                        margin-bottom: var(--within-page-padding);
                    }
                    .title {
                        color: $szoveg;
                        font-size: var(--font-medium);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium);
                        margin-bottom: 25px;
                    }
                    .description {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                        margin-bottom: 10px;
                        padding-right: 50px;
                        a:not([href]):not([class]) {
                            color: rgb(59, 113, 202);
                            text-decoration: none;
                            text-transform: uppercase;
                        }
                        li {
                            margin-left: 25px;
                            &::marker {
                                content: "-  ";
                                
                            }
                        }
                    }
                    img {
                        margin-top: 30px;
                        margin-bottom: 50px;
                    }
                    .loadmore-div {
                        margin-top: 30px;
                    }
                }
            }
        }
        .nepmese-div {
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            background-color: $vilagos;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: flex-start;
            padding-left: 6%;
            padding-top: 111px;
            @media (max-width: 1200px) {
                width: 0;
                display: none;
            }
            .awards {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                    margin-bottom: 50px;
                }
                .awards-img {
                    width: 112.238px;
                    //height: 122.842px;
                    flex-shrink: 0; 
                    margin-bottom: 60px;
                }
            }
            @media (max-width: 1980px) {
                //width: 42%;
            }
        }
    }
    
    .background-div {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        background-color: $vilagos;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        padding-left: 6%;
        padding-top: 111px;
        @media (max-width: 1200px) {
            width: 25%;
        }
        .awards {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            span {
                color: $szoveg;
                font-size: var(--font-normal);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-normal);
                margin-bottom: 50px;
            }
            .awards-img {
                width: 112.238px;
                //height: 122.842px;
                flex-shrink: 0; 
                margin-bottom: 60px;
            }
        }
        @media (max-width: 1980px) {
            //width: 42%;
        }
    }
}
.urlap-section {
    background: #E5E9F4; 
    padding-top: 64px;
    padding-bottom: 64px;

    .kapcsolat-title {
        margin-bottom: var(--within-page-padding);

        span {
            color: $szoveg;
            font-size: var(--font-medium-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium-large);
        }
    }
    .profile-form {
        @media (max-width: 811px) {
          padding-bottom: 41px;

          .col-md-6 {
            padding: 0;
          }
        }

        .form-outline {
            width: 100%;
            height: 45px;
            display: flex;
            padding: 11px 10px;
            align-items: center;
            gap: 10px;
            border: none;
            background: #FFF;
            border-radius: 0;
            .form-control {
                padding-left: 0;
                align-self: stretch;
                background: #FFF;
                outline: none;
                font-style: normal;
                font-weight: 200 !important;
                font-size: var(--font-normal);
                line-height: var(--line-height-normal);
                color: rgb(37, 38, 38);
            }
        }
        .form-outline .form-control ~ .form-notch {
            display: none;
        }
        .form-outline .form-control ~ .form-label {
            display: none;
        }
        .react-tel-input .form-control {
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            border: none;
            background: #FFF;
            border-radius: 0;
            font-style: normal;
            font-weight: 200 !important;
            font-size: var(--font-normal);
            line-height: var(--line-height-normal);
            color: rgb(37, 38, 38);
        }
        .react-tel-input .flag-dropdown {
            position: absolute;
            top: 0;
            bottom: 0;
            padding: 0;
            border: none;
            background: #FFF;
            border-radius: 0;
        }
        .form-control:focus {
            box-shadow: none;
            
        }
    }

    @media (max-width: 812px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.organization-structure-header {
  background-image: url('../../media/images/organization-structure-background.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 39px 0 47px 0;

  &-title {
    color: white;
    font-size: var(--font-medium);
    font-style: italic;
    font-weight: 700;
    line-height: var(--line-height-medium);
    text-align: center;
    margin-bottom: 53px;
  }

  &-persons {
    column-gap: 40px;
    display: flex;
    justify-content: center;

    @media (max-width: 1059px) {
      column-gap: 24px;
    }

    @media (max-width: 999px) {
      align-items: center;
      column-gap: unset;
      row-gap: 48px;
      flex-direction: column;
    }
  }
}

.organization-structure-person {
  background-color: white;
  color: #404040;
  padding: 5px 5px 11px 5px;

  &:nth-child(2n) {
    .organization-structure-person-image {
      background-color: #F0B323;
    }
  }

  &-image {
    background-color: #211551;
    height: 231px;
    position: relative;
    width: 283px;

    img {
      position: absolute;
      bottom: 0;
    }
  }

  &-name {
    font-size: var(--font-normal);
    font-style: italic;
    font-weight: 700;
    line-height: var(--line-height-normal);
    margin: 11px 0 0 19px;
  }

  &-position, &-organization {
    font-size: var(--font-small);
    line-height: var(--line-height-small);
    margin-left: 19px;
  }
}

.structure-section {
    background: $vilagos;
    position: relative;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    @media (max-width: 1270px) {
        padding: var(--page-padding-top) 20px var(--page-padding-bottom) 20px;
    }
   
    .kapcsolat-map-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .one-col-div {
            .up-div {
                display: flex;
                flex-direction: column;
                
                h3 {
                    color: #000;
                    font-size: var(--font-medium-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium-large);
                    margin-bottom: var(--within-page-padding);
                }

                .structure {
                    .title {
                        color: $szoveg;
                        font-size: var(--font-medium);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium);
                        margin: var(--within-page-padding) 0 var(--within-page-small-padding) 0;
                    }
                    .structure-row {
                        margin: 0 -.5rem;
                        row-gap: 1rem;

                        .structure-card {
                            display: flex;
                            height: 117.196px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0; 
                            background: $feher;
                            box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.16);
                            @media (max-width: 811px) {
                                padding: 10px;
                            }
                            p {
                                color: $elsodleges;
                                text-align: center;
                                font-size: var(--font-normal);
                                font-style: italic;
                                font-weight: 600 !important;
                                line-height: var(--line-height-normal);
                                margin: 0;
                            }
                            span {
                                color: $szoveg;
                                font-size: var(--font-small);
                                font-style: normal;
                                font-weight: 200 !important;
                                line-height: var(--line-height-small);
                                text-align: center;
                            }
                            a {
                                text-align: center;
                                font-size: var(--font-small);
                                text-decoration: underline;
                            }
                            div {
                                margin-bottom: 5px;

                              @media (max-width: 811px) {
                                margin-bottom: 0;
                              }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }
}
.kapcsolat-map-section-2 {
    background: $feher;
    position: relative;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    .kapcsolat-map-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        
        .left-col-div {
            display: table-cell;
            width: 50%;
            @media (max-width: 810px) {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;
            }
            
            .up-div {
                display: flex;
                flex-direction: column;
                margin-bottom: 115px;
                padding-right: 30px;
                @media (max-width: 810px) {
                    margin-bottom: 10px;
                }
                h3 {
                    color: #000;
                    font-size: var(--font-medium-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium-large);
                    margin-bottom: var(--within-page-padding);
                }
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-bottom: 10px;
                }
                a {
                    color: $kiemelt;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                }
                p {
                    overflow-wrap: break-word;
                }
                
            }
            .down-div {
                display: flex;
                flex-direction: column;
                p {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                    margin-top: 10px;
                }
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                }
            }
            .map-keret {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 441px;
                flex-shrink: 0; 
                border-radius: 4px;
                background: $feher;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04); 
                margin-top: 30px;
            }
        }
        .right-col-div {
            display: table-cell;
            width: 50%;
            padding-top: 61px;
            padding-bottom: 90px;
            .map-keret {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                flex-shrink: 0; 
                border-radius: 4px;
                background: $feher;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04); 
            }
        }
        .one-col-div {
            display: table-cell;
            width: 60%;
            padding: 61px 30px 0 30px;
            padding-bottom: 90px;
            @media (max-width: 1200px) {
                width: 75%;
            }
            @media (max-width: 811px) {
                width: 100%;
                padding: 61px 20px 0 20px;
            } 
            .navigate-div {
                margin-top: -29px;
                display: flex;
                align-items: center;
                margin-bottom: 29px;
                button {
                    color: $szoveg;
                    font-size: var(--font-small);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-small);
                    cursor: pointer;
                    background: transparent;
                    border: none; 
                    box-shadow: none;
                    &:hover {
                        color: $kiemelt;
                    }
                }
                img {
                    margin: 0 10px;
                }
            }
            .up-div {
                display: flex;
                flex-direction: column;
                margin-bottom: 115px;
                @media (max-width: 811px) {
                    margin-bottom: 25px;
                } 
                h3 {
                    color: #000;
                    font-size: var(--font-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-large);
                    margin-bottom: var(--within-page-padding);
                }
                p {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-top: 0px;
                    overflow-wrap: break-word;
                    &.title-text {
                        color: $sotet;
                        font-size: var(--font-medium-large);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium-large);
                    }
                }
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-bottom: 10px;
                    &.kiemelt-text {
                        font-size: var(--font-normal);
                        font-weight: 600 !important;
                        font-style: italic;
                        line-height: var(--line-height-normal);
                        margin-bottom: 0;
                    }
                   
                }
                ol {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                    li {
                        display: list-item;
                    }
                }
                
                a {
                    color: $kiemelt;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                }
                .teremberles-first {
                    width: 100%;
                    margin-bottom: 82px;
                }

                .tbkartya-image {
                    width: 100%;
                    margin-bottom: 45px;
                }

                .loadmore-div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-right: 12px;
                    margin-bottom: 39px;
                    button {
                        margin-top: 15px;
                        .button-text {
                            text-transform: initial; 
                            color: $vilagos;
                            margin: 0;
                        }
                        &:hover {
                            background: $kiemelt; 
                            transition: 0.6s ease-in-out;
                        }
                    }
                    svg {
                        margin-top: -4px;
                        margin-left: 4px;
                    }
                }
                .documents {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    .table th {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                        padding-left: 10px;
                        width: 50px;
                    }
                    .table td {
                        padding-left: 0;
                        .documents-download {
                            width: 100%;
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-start;
                            
                            a {
                                color: var(--Szveg, #404040);
                                font-family: Urbanist;
                                font-size: var(--font-normal);
                                font-style: normal;
                                font-weight: 200 !important;
                                line-height: var(--line-height-normal);
                                &:hover {
                                    color: #F0B323;
                                }
                            }
                        }
                    }
                   
                }
                .terem-card-div {
                    //height: 329px;
                    width: 100%;
                    margin-bottom: 23px;
                    cursor: pointer;
                    .image-div {
                        img {
                            width: 100%;
                        }
                    }
                    &.katalogus {
                        padding-right: 12px;
                        .title-div {
                            background-color: #F0B323;
                        }
                    }
                    &.agora {
                        padding-right: 12px;
                        .title-div {
                            background-color: #F0B323;
                        }
                    }
                    &.banyasz {
                        padding-left: 12px;
                        .title-div {
                            background-color: #FA8934;
                        }
                    }
                    &.ja {
                        padding-right: 12px;
                        .title-div {
                            background-color: #54A8DE;
                        }
                    }
                    &.puskin {
                        padding-left: 12px;
                        .title-div {
                            background-color: #AFCA21;
                        }
                    }
                    &.szi {
                        padding-right: 12px;
                        .title-div {
                            background-color: #9A96F7;
                        }
                    }
                    .title-div {
                        width: 100%;
                        height: 102px;
                        flex-shrink: 0; 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        p {
                            color: $sotet;
                            text-align: center;
                            font-size: var(--font-medium-large);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-medium-large);
                            max-width: 277px;
                            margin-bottom: 0;
                        }
                    }
                }
                .palyazatok-card-div {
                    display: flex;
                    height: 177px;
                    
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0; 
                    margin-bottom: 23px;
                    cursor: pointer; 
                    border: 2px solid #D4D4D4;
                    background: #FFF; 
                    padding: 0 10px;
                    &.left {
                        margin-left: 10px;
                        @media (max-width: 811px) {
                            margin-left: 0;
                        }
                    }
                    &.right {
                        margin-right: 10px;
                        @media (max-width: 811px) {
                            margin-right: 0;
                        }
                    }
                    p {
                        margin: 0;
                        color: #000;
                        text-align: center;
                        font-size: var(--font-medium);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium);
                    }
                    &:hover {
                        p {
                            color: #F0B323; 
                        }
                    }
                }
                .helyszin-div {
                    .title {
                        margin-top: 20px;
                        height: 38px; 
                        span {
                            color: $sotet;
                            font-size: var(--font-normal);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-normal); 
                        }
                    }
                    .address {
                        span {
                            color: $szoveg;
                            font-size: var(--font-normal);
                            font-style: normal;
                            font-weight: 200 !important;
                            line-height: var(--line-height-normal); 
                        }
                    }
                    .program-maps {
                        width: 100%;
                        padding: 25px 0 50px 0;
                        p {
                            color: $szoveg;
                            font-size: var(--font-normal);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-normal);
                            margin-bottom: 10px;
                        }
                        span {
                            color: $szoveg;
                            font-size: var(--font-normal);
                            font-style: normal;
                            font-weight: 200 !important;
                            line-height: var(--line-height-normal);
                            
                        }
                        .map-keret {
                            margin-top: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 441px; 
                            flex-shrink: 0; 
                            border-radius: 4px;
                            background: $feher;
                            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04); 
                        }
                    }
                }
                .gallery {
                    max-width: 811px;
                    padding: 0;
                    position: relative;
                    margin-bottom: 60px;
                    .gallery-title {
                        margin-top: 20px;
                        height: 38px; 
                        span {
                            color: $sotet;
                            font-size: var(--font-normal);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-normal); 
                        }
                        
                    }
                }
                .video {
                    margin-top: 60px;
                }
                .image-div {
                    width: 100%;
                    //height: 394px;
                    background: rgba(33, 21, 81, 0.90); 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        height: 227px;
                    }
                }
            }
            .down-div {
                display: flex;
                flex-direction: column;
                p {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal); 
                    margin-top: 10px;
                }
                span {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                }
                a {
                    color: $kiemelt;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                }
            }
        }
    }
    .background-div {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        background-color: $vilagos;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        padding-left: 6%;
        padding-top: 111px;
        @media (max-width: 1200px) {
            width: 25%;
        }
        .awards {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            span {
                color: $szoveg;
                font-size: var(--font-normal);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-normal);
                margin-bottom: 50px;
            }
            .awards-img {
                width: 112.238px;
                //height: 122.842px;
                flex-shrink: 0; 
                margin-bottom: 60px;
            }
        }
        @media (max-width: 1980px) {
            //width: 42%;
        }
    }
}
