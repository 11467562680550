.programdetail-section {
  .table-div {
    display: table;
    position: relative;
    .left {
      width: 60%;
      display: table-cell;
      vertical-align: top;
      .programdetail-header {
        .program-background-image {
          position: relative;
          img {
            width: 100%;
          }
          .datum-div {
            position: absolute;
            bottom: -20.4px;
            left: calc((min(100vw, 1920px) - 1008px) / 2);
            display: flex;
            align-items: center;
            justify-content: center;
            width: unset;
            padding-left: 15px;
            padding-right: 15px;
            height: 40.8px;
            flex-shrink: 0;
            background: $elsodleges;
            z-index: 10;

            span {
              color: $feher;
              text-align: center;
              font-size: var(--font-medium);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-medium);
            }

            @media (min-width: 812px) and (max-width: 1248px) {
              left: 20px;
            }
          }
        }
      }
    }
    .right {
      width: 40%;
      display: table-cell;
      vertical-align: top;
      position: relative;
      .program-detail-up {
        position: absolute;
        top: 0;
        width: 100%;
        padding: 0 0 0 30px;
        height: calc(100% - 136px);
        @media (max-width: 1000px) {
          height: calc(100% - 100px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 900px) and (max-width: 1340px) {
          padding: 0 0 0 24px;
        }
        @media (max-width: 899.98px) {
          padding: 0 0 0 24px;
        }
        .propgram-title {
          height: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          span {
            width: calc(40vw - ((100vw - 1008px) / 2) - 30px);
            color: $sotet;
            font-size: var(--font-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-large);
            max-height: calc(var(--line-height-large) * 3);
            overflow: hidden;
            max-width: 100%;

            @media (min-width: 812px) and (max-width: 1248px) {
              max-height: calc(var(--line-height-large) * 2);
            }
          }
        }
        .program-description {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 60%;

          .title {
            color: $szoveg;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            margin-bottom: 0;
            margin-top: 10px;
          }
          .text {
            color: $szoveg;
            font-size: var(--font-normal);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
          }

          @media (min-width: 812px) and (max-width: 1248px) {
            p:nth-child(1) {
              display: none;
            }
            span:nth-child(2) {
              display: none;
            }
          }
        }
      }
      .program-detail-down {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 136px;
        flex-shrink: 0;
        background: $kiemelt;
        padding-left: 34px;
        padding-top: 34px;
        @media (min-width: 900px) and (max-width: 1340px) {
          padding-left: 24px;
        }
        @media (max-width: 899.98px) {
          padding-left: 24px;
        }
        p {
          color: $sotet;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          height: var(--line-height-normal);
          overflow: hidden;
          line-height: var(--line-height-normal);
          margin-bottom: 9px;
          width: calc(40vw - ((100vw - 1008px) / 2) - 30px);
          max-width: 100%;
        }
        @media (max-width: 1000px) {
          height: 100px;
          padding-left: 20px;
          padding-top: 15px;
        }
      }
    }
  }
  .table-div-2 {
    width: 100%;
    display: table;
    position: relative;
    .left {
      width: 60%;
      display: table-cell;
      vertical-align: top;
      background-color: $vilagos;

      @media (min-width: 812px) and (max-width: 1248px) {
        width: calc(60% - 20px);
        padding-left: 20px;
      }

      .up-div {
        padding: var(--within-page-padding) 0 0
          calc((min(100vw, 1920px) - 1008px) / 2);
        background-color: $feher;
        .loadmore-div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          button {
            margin-top: 15px;
            min-width: 200px !important;
            .button-text {
              text-transform: initial;
              color: $vilagos;
              margin: 0;
            }
            &:hover {
              background: $kiemelt;
              transition: 0.6s ease-in-out;
            }
          }
          svg {
            margin-top: -4px;
            margin-left: 4px;
          }
        }
        .programdetail-description {
          font-size: var(--font-normal);
          padding: 0 13% 0 0;
          height: 100%;
          .gallery {
            max-width: 811px;
            padding: 0;
            position: relative;
            .gallery-title {
              margin-top: 20px;
              height: 38px;
              span {
                color: $sotet;
                font-size: var(--font-normal);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-normal);
              }
            }
          }
        }
      }
      .program-maps {
        width: 100%;
        padding: 25px 0 50px calc((min(100vw, 1920px) - 1008px) / 2);
        p {
          color: $szoveg;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-medium);
          margin-bottom: 10px;
        }
        span {
          color: $szoveg;
          font-size: var(--font-normal);
          font-style: normal;
          font-weight: 200 !important;
          line-height: var(--line-height-medium);
        }
        .map-keret {
          margin: 16px 13% 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 441px;
          flex-shrink: 0;
          border-radius: 4px;
          background: $feher;
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
        }
      }
    }
    .right {
      width: 40%;
      display: table-cell;
      vertical-align: top;
      position: relative;
      background: #e5e9f4;
      .kozelgo-programok {
        height: 100%;
        //background: #E5E9F4;
        margin-left: 34px;
        width: calc(40vw - ((100vw - 1008px) / 2) - 30px);

        @media (min-width: 900px) and (max-width: 1340px) {
          margin-left: 24px;
        }
        padding-bottom: 50px;
        .program-title {
          padding-top: 72px;
          span {
            color: $elsodleges;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            text-decoration-line: underline;
          }
        }
        .program-card-div {
          width: 100%;
          max-width: 360px;
          flex-shrink: 0;
          background: $feher;
          box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.16);
          margin-bottom: 24px;
          cursor: pointer;
          &:hover {
            .image-div {
              .overlay {
                opacity: 0.4;
                transition: 0.6s;
              }
            }
            .datum-div {
              background: $elsodleges;
              transition: 0.8s;
              span {
                color: $feher;
                transition: 0.8s;
              }
            }
          }
          .image-div {
            overflow: hidden;
            position: relative;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: left top;
            @media (max-width: 899.98px) {
              background-size: 100% 100%;
            }
            .program-image {
              height: 100%;
            }
            .overlay {
              background: rgb(0, 0, 0);
              height: 100%;
              //width: 100%;
              opacity: 0;
              transition: 0.6s;
            }
            .program-ikon-image {
              position: absolute;
              left: 11.89px;
              top: 14.28px;
              width: 49.92px;
              height: 49.92px;
              flex-shrink: 0;
              z-index: 2500;
              &:hover {
                width: 71px;
                height: 71px;
              }
            }
          }
          .datum-div {
            width: 100%;
            max-width: 360px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $kiemelt;
            transition: 0.8s;
            span {
              color: $sotet;
              text-align: center;
              font-size: var(--font-normal);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-normal);
              transition: 0.8s;
            }
          }
          .text-div {
            height: 142px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 10px;

            p {
              color: $sotet;
              text-align: center;
              font-size: var(--font-normal);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-normal);
              margin-bottom: 18px !important;
            }
            span {
              color: $szoveg;
              text-align: center;
              font-size: var(--font-normal);
              font-style: normal;
              font-weight: 200 !important;
              line-height: var(--line-height-normal);
            }
          }
        }
      }
    }
  }
}

.programdetail-section-respo {
  .programdetail-header {
    display: flex;
    flex-direction: column;
    .program-background-image {
      position: relative;
      img {
        width: 100%;
      }
      .datum-div {
        position: absolute;
        bottom: 0;
        translate: 0 50%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        padding: 4px 24px;
        flex-shrink: 0;
        background: $elsodleges;

        @media (max-width: 811px) {
          left: 20px;
          transform: unset;
        }

        span {
          color: $feher;
          text-align: center;
          font-size: var(--font-medium-large);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-medium-large);
        }
      }
    }
    .navigate-div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 29px;
      padding: 0 20px;
    }
    .program-detail-up {
      width: 100%;
      padding: 0 20px 30px 20px;
      height: calc(100% - 136px);
      .propgram-title {
        max-width: 85%;
        span {
          color: $sotet;
          font-size: var(--font-large);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-large);
        }
      }
    }
    .program-detail-down {
      width: 100%;
      min-height: 72px;
      flex-shrink: 0;
      background: $kiemelt;
      padding-left: 24px;
      padding-top: 22px;
      p {
        color: $sotet;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-bottom: 9px;
      }
      button {
        margin-bottom: 22px;
      }
    }
    .up-div {
      padding: 36px 20px 0 20px;
      .loadmore-div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        button {
          margin-top: 15px;
          min-width: 200px !important;
          .button-text {
            text-transform: initial;
            color: $vilagos;
            margin: 0;
          }
          &:hover {
            background: $kiemelt;
            transition: 0.6s ease-in-out;
          }
        }
        svg {
          margin-top: -4px;
          margin-left: 4px;
        }
      }
      .programdetail-description {
        //padding: 0 95px 0 0;
        height: 100%;
        p {
          width: 100%;
        }
      }
    }
    .program-maps {
      //position: absolute;
      bottom: 0;
      background-color: $vilagos;
      width: 100%;
      padding: 25px 20px 50px 20px;
      p {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-bottom: 10px;
      }
      span {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 200 !important;
        line-height: var(--line-height-normal);
      }
      .map-keret {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 441px;
        flex-shrink: 0;
        border-radius: 4px;
        background: $feher;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
      }
    }
    .org {
      @media (max-width: 811px) {
        display: none;
      }
    }

    .gallery {
      max-width: 811px;
      padding: 0 20px;
      position: relative;
      .gallery-title {
        margin-top: 20px;
        height: 38px;
        span {
          color: $sotet;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-normal);
        }
      }
    }
    .program-description {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 20px 30px 20px;
      background-color: $vilagos;
      .title {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-bottom: 0;
        margin-top: 15px;
      }
      .text {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 200 !important;
        line-height: var(--line-height-normal);
      }
    }
    .kozelgo-programok {
      height: 100%;
      background: #e5e9f4;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 100px;
      .program-title {
        padding-top: 20px;
        span {
          color: $elsodleges;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-normal);
          text-decoration-line: underline;
        }
      }
      .program-card-div {
        flex-shrink: 0;
        background: $feher;
        box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.16);
        margin-bottom: 24px;
        cursor: pointer;
        &:hover {
          .image-div {
            .overlay {
              opacity: 0.4;
              transition: 0.6s;
            }
          }
          .datum-div {
            background: $elsodleges;
            transition: 0.8s;
            span {
              color: $feher;
              transition: 0.8s;
            }
          }
        }
        .image-div {
          position: relative;
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: left top;
          overflow: hidden;
          .overlay {
            background: rgb(0, 0, 0);
            height: 100%;
            //width: 100%;
            opacity: 0;
            transition: 0.6s;
          }
          .program-ikon-image {
            position: absolute;
            left: 11.89px;
            top: 14.28px;
            width: 49.92px;
            height: 49.92px;
            flex-shrink: 0;
            z-index: 2500;
            &:hover {
              width: 71px;
              height: 71px;
            }
          }
        }
        .datum-div {
          width: 100%;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $kiemelt;
          transition: 0.8s;
          span {
            color: $sotet;
            text-align: center;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            transition: 0.8s;
          }
        }
        .text-div {
          height: 142px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 10px;

          p {
            color: $sotet;
            text-align: center;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            margin-bottom: 18px !important;
          }
          span {
            color: $szoveg;
            text-align: center;
            font-size: var(--font-normal);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
          }
        }
      }
    }
  }
  .programdetail-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .up-div {
      padding: 0 0 0 20px;
      .loadmore-div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        button {
          margin-top: 15px;
          min-width: 200px !important;
          .button-text {
            text-transform: initial;
            color: $vilagos;
            margin: 0;
          }
          &:hover {
            background: $kiemelt;
            transition: 0.6s ease-in-out;
          }
        }
        svg {
          margin-top: -4px;
          margin-left: 4px;
        }
      }
      .programdetail-description {
        padding: 0 95px 0 0;
        height: 100%;
        .gallery {
          max-width: 811px;
          padding: 0;
          position: relative;
          .gallery-title {
            margin-top: 20px;
            height: 38px;
            span {
              color: $sotet;
              font-size: var(--font-normal);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-normal);
            }
          }
        }
      }
    }
  }
}
