.felnottkepzes-section {
    background: $feher;
    position: relative;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    @media (max-width: 811px) {
        padding: 0 20px 55px 20px;
    }
    .kapcsolat-map-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .one-col-div {
            display: table-cell;

            .up-div {
                display: flex;
                flex-direction: column;
                
                h3 {
                    color: #000;
                    font-size: var(--font-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-large);
                }
            }
        }
    }
    .kapcsolat-map-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .one-col-div {
            display: table-cell;
            width: 60%;
            @media (max-width: 811px) {
                width: 100%;
                padding-bottom: 0;
            }
            .up-div {
                display: flex;
                flex-direction: column;
                margin-bottom: 115px;
                h3 {
                    color: #000;
                    font-size: var(--font-medium-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium-large);
                    margin-bottom: var(--within-page-padding);
                }
            }
            .accordion-div {
                .title-text {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-bottom: 24px;
                    span {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                    }
                }
                .accordion-tag {
                    .accordion-button:not(.collapsed) {
                        color: $elsodleges;
                        font-size: var(--font-normal);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-normal);
                        &::after {
                            display: none;
                        }
                        &::before {
                            flex-shrink: 0;
                            width: 10px;
                            height: 2px; 
                            margin-left: 0;
                            margin-right: 10px;
                            content: "";
                            background-image: url('../../media/icons/minus.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .accordion-button {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-normal);
                        &::after {
                            display: none;
                        }
                        &::before {
                            flex-shrink: 0;
                            width: 10px;
                            height: 10px; 
                            margin-left: 0;
                            margin-right: 10px;
                            content: "";
                            background-image: url('../../media/icons/plus.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .accordion-body {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                    }
                }
            }
        }
    }
    .background-div {
        position: absolute;
        top: 0;
        right: 0;
        width: 42vw;
        background-color: $vilagos;
        height: 100%;
        z-index: 1;
        @media (max-width: 1980px) {
            //width: 42%;
        }
    }
}