.vertesagoraja-section {
    background: $feher;
    position: relative;
    .vertesagoraja-div {
        background: $feher;
        max-width: 1186px;
        padding-top: 52px;
        padding-bottom: 100px;
        min-height: calc(100vh - 617px);
        .title-text {
            padding-left: 318px;
            margin-bottom: 46px;
            span {
                color: $sotet;
                font-size: var(--font-large);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-large);
            }
        }
        .description-text {
            max-width: 1108px;
            padding-left: 318px;
            margin-bottom: 10px;
            span {
                color: $szoveg;
                font-size: var(--font-normal);
                font-style: normal;
                font-weight: 200 !important;
                line-height: var(--line-height-normal);
            }
        }
    }
}