.agora-footer {
    width: 100%;
    min-height: 512px;
    flex-shrink: 0; 
    background: $elsodleges; 
    position: relative;
    .szechenyi-logo {
        position: absolute;
        bottom: 0;
        right: 0;
        flex-shrink: 0; 
        width: 21%;
        max-width: 464.422px;
        max-height: 320.95px; 
    }
    .footer-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: var(--section-padding) 20px 0 20px;
        .footer-culomn-div {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            .elso-oszlop {
                img {
                    //width: 243px;
                    height: 37.6px;
                    flex-shrink: 0; 
                    margin-bottom: 36.8px;
                    @media (max-width: 960px) {
                        height: 40px;
                    }
                }
                p {
                    color: $feher;
                    font-size: var(--font-medium);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium);
                }
                span {
                    color: $vilagos;
                    font-size: var(--font-normal);
                    font-style: normal;
                    line-height: var(--line-height-normal);
                    opacity: 0.9;
                }
                a {
                    color: $vilagos;
                    font-size: var(--font-normal);
                    font-style: normal;
                    line-height: var(--line-height-normal);
                    opacity: 0.9;
                    cursor: pointer;
                    text-decoration-line: underline;
                }
            }
            .masodik-oszlop {
                margin-left: 40px;
                margin-right: 40px;

                p {
                    color: $feher;
                    font-size: var(--font-medium);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: 1;
                    &.jegyvasarlas {
                        padding-top: 13px;
                        color: $vilagos;
                        font-size: var(--font-medium);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: 1;
                    }
                }
                span {
                    color: $vilagos;
                    font-size: var(--font-normal);
                    font-style: normal;
                    line-height: var(--line-height-normal);
                    opacity: 0.9;
                }
            }
            .harmadik-oszlop {
                p {
                    color: $feher;
                    font-size: var(--font-medium);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium);
                }
                img {
                    width: 40.8px;
                    height: 40.8px;
                    flex-shrink: 0; 
                    margin-right: 33px;
                    margin-bottom: 48px;
                    @media (max-width: 960px) {
                        width: 40px;
                        height: 40px;
                        margin-right: 20px;
                    }
                    &.nka-logo {
                        width: auto;
                        height: 83px; 
                        margin: 0;
                        margin-top: 38px;
                    }

                    &:nth-child(4) {
                        margin-right: 0;
                    }
                }
                .form-outline {
                    width: 297px;
                    .form-control {
                        min-height: 48px;
                        padding-top: .32rem;
                        padding-bottom: .32rem;
                        padding-left: .75rem;
                        padding-right: .75rem;
                        border: 1px solid #BDBDBD;
                        border-radius: 0;
                        background: $feher; 
                        transition: all .2s linear;
                        color: $elsodleges; 
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                    }
                    .form-control ~ .form-label {
                        position: absolute;
                        top: 50%;
                        padding-top: 0;
                        transform: translateY(-50%);
                        margin-bottom: 0;
                        color: #BDBDBD; 
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                    }
                    .form-control:focus ~ .form-label {
                        display: none;
                    }
                    .form-control.active ~ .form-label {
                        display: none;
                    }
                    .form-control ~ .form-notch {
                        display: none !important;
                    }
                }
            }
        }
        .footer-licensz-div {
            margin-bottom: var(--section-padding);
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: var(--font-small);

            span {
                color: $vilagos;
                font-size: var(--font-small);
                font-style: normal;
                line-height: var(--line-height-normal);
                opacity: 0.9;
            }
            img {
                height: 26px; 
                width: 322px;
                margin-top: 5px;
            }
        }
        .jogi-div {
            display: flex;
            margin-top: var(--section-padding);
            margin-bottom: var(--section-padding);
            width: 100vw;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.10);
            height: 58px; 
            padding-left: 20px;
            a {
                margin-right: 36px;
                color: #FFF;
                font-size: var(--font-normal);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-normal);
            }
        }
    }
}
.agora-footer-respo {
    width: 100%;
    flex-shrink: 0;
    background: $elsodleges; 
    position: relative;
    padding: 0 20px;
    .footer-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding-top: 48px;
        .footer-culomn-div {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            .elso-oszlop {
                .logo-img {
                    //width: 243px;
                    height: 47px;
                    flex-shrink: 0; 
                    margin-bottom: 26px;
                }
                p {
                    color: $feher;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                    margin-top: 24px;
                    margin-bottom: 5px;
                    &.jegyvasarlas {
                        padding-top: 13px;
                        color: $vilagos;
                        font-size: var(--font-normal);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-normal);
                    }
                }
                .normal-text {
                    color: $vilagos;
                    font-size: var(--font-small);
                    font-style: normal;
                    line-height: var(--line-height-small);
                    opacity: 0.9;
                }
                a {
                    color: $vilagos;
                    font-size: var(--font-small);
                    font-style: normal;
                    font-weight: 400;
                    line-height: var(--line-height-small);
                    cursor: pointer;
                    text-decoration-line: underline;
                }
                .social-logos {
                    width: 48px;
                    height: 48px;
                    flex-shrink: 0; 
                    margin-right: 33px;
                    //margin-bottom: 25px;
                    @media (max-width: 811px) {
                        width: 41px;
                        height: 41px;
                    }
                }
                .form-outline {
                    width: 297px;
                    .form-control {
                        min-height: 48px;
                        padding-top: .32rem;
                        padding-bottom: .32rem;
                        padding-left: .75rem;
                        padding-right: .75rem;
                        border: 1px solid #BDBDBD;
                        border-radius: 0;
                        background: $feher; 
                        transition: all .2s linear;
                        color: $elsodleges; 
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                    }
                    .form-control ~ .form-label {
                        position: absolute;
                        top: 50%;
                        padding-top: 0;
                        transform: translateY(-50%);
                        margin-bottom: 0;
                        color: #BDBDBD; 
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                    }
                    .form-control:focus ~ .form-label {
                        display: none;
                    }
                    .form-control.active ~ .form-label {
                        display: none;
                    }
                    .form-control ~ .form-notch {
                        display: none !important;
                    }
                }
            }
        }
        .footer-licensz-div {
            margin: 41px 0 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                height: 20px; 
                width: auto;
                margin-top: 5px;
            }
        }
    }
}