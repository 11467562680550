.hirlevelpage-section {
    background: $elsodleges;
    height: 138px; 
    @media (max-width: 811px) {
        padding: 0 20px;
    }
    .hirlevel-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 138px;
        span {
            color: $feher;
            font-size: var(--font-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-large);
        }
    }
}
.hirlevelform-section {
    @media (max-width: 811px) {
        padding: 20px 20px 0 20px;
    }
    min-height: 545px;
    flex-shrink: 0; 
    background: #E5E9F4;
    padding-top: 62px;
    .hirlevelform-div {
        .title-text {
            color: $szoveg;
            font-size: var(--font-medium-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium-large);
           
        }
        .description-text {
            color: $szoveg;
            font-size: var(--font-medium);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium);
           
        }
        max-width: 600px;
    }
    .regisztracioform-div {
        .title-text {
            color: $szoveg;
            font-size: var(--font-medium-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium-large);
           
        }
        .description-text {
            color: $szoveg;
            font-size: var(--font-medium);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium);
           
        }
        max-width: 735px;
    }
}

.input-group-text {
    height: 45px!important;
    border: none;
    background-color: #ffffff;
    border-radius: 0;
}

.underline {
    width: 600px;
    height: 1px; 
    background: #D4D4D4; 
    margin: 53px 0;
}

.jelszo-text {
    color: #2D2D2D;
    font-size: var(--font-normal);
    font-style: italic;
    font-weight: 200 !important;
    line-height: var(--line-height-normal);
}