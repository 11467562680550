.agora-header {
    position: relative;
    display:contents;
    .information-bar {
        width: 100%;
        height: 24px;
        flex-shrink: 0; 
        background: $kiemelt; 
        box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.08);
        z-index: 2600;
        position: relative;
        @media (max-width: 810.98px) {
            display: none;
        }
        .info-ikon {
            width: 16px;
            height: 16px; 
            margin-right: 10px;
        }
        .info-text {
            color: $szoveg;
            font-size: var(--font-extra-small);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-extra-small);
            margin-right: 35px;
        }
    }
    .navbar {
        width: 100%;
        height: 60px;
        padding: 0;
        background: $feher; 
        box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.08); 
        position: sticky;
        top:0;
        z-index: 2600;
        @media (max-width: 1284px) {
            height: 60px;
        }
        /*@media (max-width: 811px) {
          position: fixed;
        }*/
        .navbar-brand {
            cursor: pointer;
            li {
                list-style: none;
            }
            img {
                //width: 216px;
                height: 42px;
                flex-shrink: 0; 
            }
        }
        .container {
          @media (min-width: 811px) and (max-width: 1008px) {
            padding-left: 20px !important;
            padding-right: 20px !important;
          }
        }
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-toggle {
            margin-right: 33px;
            padding: 0 !important;
            color: #011111;
            text-align: left;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            position: relative;
            background-color: transparent;
            box-shadow: none; 
            text-transform: capitalize; 
            height: 30.5px;
            &:hover {
                color: $kiemelt;
                
            }
            &.active {
                &::before {
                    
                        position: absolute;
                        margin: auto;
                        z-index: 1;
                        content: "";
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        border-radius: 1px;
                        background-color: $kiemelt;
                }
            }
            
            &:focus-visible {
                background-color: red;
            }
        }
        .dropdown-menu {
            background: $vilagos; 
            border-radius: 0;
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10); 
            position: absolute;
            inset: 0px auto auto 0px;
            padding: 3px 0 16px 0;
            transform: translate(-24px, 47px) !important;
            width: max-content;
            &.hover {
                display: block !important;
                left: 50%;
                top: -53px;
                background: $vilagos; 
            }
            li {
                position: relative;
                border-radius: 0;
                display: flex;
                align-items: center;
            }
            a {
                padding: 7px 0 7px 24px !important;
                color: $szoveg;
                text-align: left;
                font-size: var(--font-normal);
                font-style: normal;
                font-weight: 200 !important;
                line-height: var(--line-height-normal);
                &:hover {
                    color: $kiemelt;
                    
                }
                &.active {
                    color: $kiemelt;
                    background-color: transparent;
                    &::before {
                        display: none;
                    }
                }
            }
            .active {
                color: #16181b;
                background-color: blue;
            }
        }
        a {
            margin-right: 26.4px;
            padding: 0 !important;
            color: $sotet;
            text-align: left;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            position: relative;
            &:hover {
                color: $kiemelt;
                
            }
            &.active {
                &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -3px;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    border-radius: 1px;
                    background-color: $kiemelt;
                }
            }

          @media (min-width: 811px) and (max-width: 1000px) {
              margin-right: 20px;
          }
        }
    }
    .search-div {
        cursor: url('../../media/icons/Kereses bezarasa kurzor.svg'), not-allowed;
        position: fixed;
        background-color:  transparent;
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        top: 0;
        padding-top: 105px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100vw;
        height: 100vh;
        z-index: 2550;
        @media (max-width: 1284px) {
            top: 65px;
        }
        @media (max-width: 502px) {
            top: 0;
        }
        .search-tag {
            position: relative;
            width: 100%;
            max-width: 1256px;
            display: flex;
            align-items: center;
            z-index: 1500;
            .form-outline {
                width: 100%;
                height: 53px;
                .form-control {
                    min-height: 53px;
                    padding-top: .32rem;
                    padding-bottom: .32rem;
                    padding-left: 30px;
                    padding-right: .75rem;
                    border: 1px solid #BDBDBD;
                    border-radius: 0;
                    background-color: rgba(248, 250, 255, 0.9); 
                    transition: all .2s linear;
                    color: #8B8B8B;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                }
                .form-control ~ .form-label {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    padding-top: 0;
                    padding-left: 30px;
                    transform: translateY(-50%);
                    margin-bottom: 0;
                    color: #8B8B8B;
                    text-align: center;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                }
                .form-control:focus ~ .form-label {
                    display: none;
                }
                .form-control.active ~ .form-label {
                    display: none;
                }
                .form-control ~ .form-notch {
                    display: none !important;
                }
            }
            .datum-search-button {
                right: 38px;
                position: absolute;
                img {
                    width: 22px;
                    height: 22px;
                    flex-shrink: 0; 
                }
            }
        }
        .close-tag {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            button {
                margin-top: 76px;
                background-color: transparent;
                border: none;
                box-shadow: none;
            }
        }
        
    }
    
   
}
