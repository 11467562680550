.agora-calendar {
  background-color: #011111;
  color: white;
  display: flex;
  height: 53px;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  width: calc(100% - 2px);

  .month-list {
    display: none;
    flex-direction: column;
    position: absolute;
    width: 120px;
    z-index: 100;

    .month {
      align-items: center;
      background-color: rgba(1, 17, 17, 0.8);
      display: flex;
      font-size: var(--font-small);
      height: 53px;
      justify-content: center;
      text-align: center;
      padding: 1px 0;

      &:hover {
        color: var(--box-bg);
        cursor: pointer;
      }
    }
  }

  .month-select {
    align-items: center;
    display: flex;
    font-size: var(--font-small);
    font-weight: 600;
    justify-content: center;
    margin-right: 8px;
    padding: 1px 6px;
    width: 120px;

    &:hover {
      color: var(--box-bg);
      cursor: pointer;
    }

    @media (max-width: 810px) {
      font-size: 16px;
    }
  }

  .day-select {
    display: flex;
    padding: 7.5px 0;

    @media(max-width: 1250px) {
      overflow-x: scroll;
      padding-right: 56px;
      width: min-content;
    }

    .day {
      border-radius: 6px;
      color: #FFFFFF80;
      cursor: default;
      font-size: var(--font-small);
      line-height: var(--line-height-small);
      margin-right: 8px;
      padding: 10px 3px;

      &--active {
        color: white;
        cursor: pointer;
        font-weight: 600;

        &:hover {
          color: var(--box-bg);
        }
      }

      &--selected {
        color: var(--box-bg);
      }

      &--current {
        background-color: var(--box-bg);
        color: black;
        font-weight: 600 !important;
        min-width: 25px;
        text-align: center;

        &.day--active:hover {
            color: white;
        }
      }

      @media (max-width: 810px) {
        font-size: 16px;
      }
    }
  }
}