.hirekdetailpage-section {

    .hirek-kep {
        margin-top: -25px;
        margin-bottom: var(--page-padding-top);
    }

    .hirek-breadcrumb {
        display: flex;
        gap: 11px;
        margin-bottom: var(--within-page-padding);

        a {
            color: #404040;
            font-size: var(--font-small);
            font-style: italic;
            font-weight: 600;
            line-height: var(--line-height-small);
        }
    }

    .hirek-background {
        background-color: #E5E9F4;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        right: calc(50% + 332.5px);
        z-index: -1000;
    }

    .boritokep-div {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .hirek-detail {
        display: flex;
        align-items: flex-start;
        position: relative;
        flex-direction: row;
        padding-top: 25px;
        padding-bottom: 208px;
        max-width: 100%;
        .left-side {
            display: flex;
            width: 297px;
            padding-left: 23px;
            position: relative;
            @media (max-width: 1284px) {
                width: 20%;
            } 
            .first-clolumn {
                color: $szoveg;
                display: flex;
                font-size: var(--font-normal);
                font-style: italic;
                line-height: var(--line-height-normal);
                flex-direction: column;
                .datum {
                    font-weight: 600 !important;
                    margin-bottom: 12px;
                }
                .title {
                    font-weight: 600 !important;
                    margin-bottom: 12px;
                }
                .description {
                    font-weight: 200 !important;
                    font-style: normal;
                    cursor: pointer;
                }
            }
            
        }
        .right-side {
            padding-left: 107px;
            width: calc(100% - 297px - 23px);
            @media (max-width: 1284px) {
                padding-left: 6%;
            }
            .second-column {
                display: flex;
                flex-direction: column;
                .loadmore-div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-right: 12px;
                    margin-bottom: 10px;
                    button {
                        margin-top: 15px;
                        min-width: 200px!important;
                        max-width: 100%;
                        .button-text {
                            text-transform: initial; 
                            color: $vilagos;
                            margin: 0;
                        }
                        &:hover {
                            background: $kiemelt; 
                            transition: 0.6s ease-in-out;
                        }
                    }
                    svg {
                        margin-top: -4px;
                        margin-left: 4px;
                    }
                }
                .title {
                    color: $sotet;
                    font-size: var(--font-medium-large);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-medium-large);
                    margin-bottom: var(--within-page-padding);
                }
                .description {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);

                  blockquote {
                    border-left: 4px solid gray;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding-left: 16px;
                    margin-left: 16px;
                  }
                }
                .tovabbi-informaciok {
                    color: $szoveg;
                    font-size: var(--font-normal);
                    font-style: normal;
                    font-weight: 200 !important;
                    line-height: var(--line-height-normal);
                    margin-top: 15px;
                    a {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                        text-decoration-line: underline;
                    }
                }
                .gallery {
                    max-width: 811px;
                    padding: 0;
                    position: relative;
                    .gallery-title {
                        margin-top: 20px;
                        height: 38px; 
                        span {
                            color: $sotet;
                            font-size: var(--font-normal);
                            font-style: italic;
                            font-weight: 600 !important;
                            line-height: var(--line-height-normal);
                        }
                        
                    }
                }
                .video {
                    margin-top: 60px;

                    iframe {
                        aspect-ratio: 811 / 456;
                        height: auto;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.hirekdetailpage-section-respo {
    
    .boritokep-div {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .hirek-detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        max-width: 100%;
        padding: 25px 20px 30px 20px;
        .datum {
            color: $szoveg;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            margin-bottom: 10px;
        }
        .title-url {
            color: $szoveg;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
            margin-bottom: 10px;
        }
        .description-url {
            color: $szoveg;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
            margin-bottom: 10px;
            text-decoration-line: underline;
            cursor: pointer;
        }
        .loadmore-div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-right: 12px;
            margin-bottom: 10px;
            button {
                margin-top: 15px;
                min-width: 200px!important;
                .button-text {
                    text-transform: initial; 
                    color: $vilagos;
                    margin: 0;
                }
                &:hover {
                    background: $kiemelt; 
                    transition: 0.6s ease-in-out;
                }
            }
            svg {
                margin-top: -4px;
                margin-left: 4px;
            }
        }
        .title {
            color: $sotet;
            font-size: var(--font-medium-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium-large);
            margin-bottom: 26px;
            margin-top: 20px;
        }
        .description {
            color: $szoveg;
            font-size: var(--font-normal);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
        }
        .tovabbi-informaciok {
            color: $szoveg;
            font-size: var(--font-normal);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
            margin-top: 15px;
            a {
                color: $szoveg;
                font-size: var(--font-normal);
                font-style: normal;
                font-weight: 200 !important;
                line-height: var(--line-height-normal);
                text-decoration-line: underline;
            }
        }
        .gallery {
            max-width: 811px;
            padding: 0;
            position: relative;
            .gallery-title {
                margin-top: 20px;
                height: 38px; 
                span {
                    color: $sotet;
                    font-size: var(--font-normal);
                    font-style: italic;
                    font-weight: 600 !important;
                    line-height: var(--line-height-normal);
                }
                
            }
        }
        .video {
            margin-top: 60px;
        }
    }
}