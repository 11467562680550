.muster-button {
    display: flex;
    padding: 11.2px 13.6px;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    flex-shrink: 0;
    box-shadow: none;
    border: none;
    box-shadow: 0;
    span {
        text-align: center;
        font-size: var(--font-small);
        font-style: italic;
        font-weight: 600 !important;
        line-height: normal;
        text-transform: uppercase; 
    }

    &.small {
        span {
            font-size: var(--font-extra-small);
        }
    }

    //AVA
    &.elsodleges {
        background: $kiemelt; 
        transition: 0.6s;
        span {
            color: $elsodleges;
        }
    }

    &.application {
        &:hover {
            background: $elsodleges;
            transition: 0.6s; 
            span {
                color: $vilagos;
            }
        }
    }
    
    &.kiemelt {
        background: $elsodleges;
        transition: 0.6s; 
        span {
            color: $vilagos;
        }
        &:hover {
            background: $kiemelt; 
            //border: 1px solid $vilagos;
            transition: 0.6s;
            span {
                color: $elsodleges;
            }
        }
    }
    &.kiemelt-next {
        background: $elsodleges;
        transition: 0.6s; 
        span {
            color: $vilagos;
            text-transform: capitalize;
        }
        &::after {
            flex-shrink: 0;
            width: 7px;
            height: 13px; 
            margin-left: 0;
            content: "";
            background-image: url('../../media/icons/next.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        &:hover {
            background: $kiemelt; 
            transition: 0.6s;
            span {
                color: $elsodleges;
            }
            &::after {
                background-image: url('../../media/icons/next-hover.svg');
            }
        }
    }
    &.jegyvasarlas {
        &:hover {
            background: $vilagos; 
            //border: 1px solid $vilagos;
            transition: 0.6s;
            span {
                color: $elsodleges;
            }
        }
    }
    
    &.szoveg {
        border: 1px solid $szoveg;
        background: $feher; 
        span {
            color: $szoveg;
        }
    }
    
    &.vilagos {
        border: 1px solid $elsodleges;
        background: $feher; 
        span {
            color: $elsodleges;
        }
    }
    
    &.feher {
        border: 1px solid $kiemelt;
        background: $feher;  
        span {
            color: $kiemelt;
        }
    }
    &.transparent {
        border: 1px solid $szoveg;
        background: transparent; 
        margin-left: 24px; 
        padding: 8px 22px;
        span {
            color: $szoveg;
        }
    }
}

.jegyvasarlas-button {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    width: 140px!important;
    height: 32px!important;
    //padding: 6px 23px;
    align-items: center!important;
    gap: 10px!important;
    flex-shrink: 0!important; 
    border: 1px solid $kiemelt!important;
    background: $kiemelt!important;
    margin-right: 26.4px!important;
    span {
        color:  $sotet!important;
        text-align: center!important;
        font-size: var(--font-small) !important;
        font-style: italic!important;
        font-weight: 600!important;
        line-height: var(--line-height-small) !important;
    }
}

.feliratkozom-button {
    display: flex;
    width: 189px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    flex-shrink: 0; 
    border: 1px solid $kiemelt;
    background: $kiemelt;

    span {

        color: $elsodleges;
        text-align: center;
        font-size: var(--font-extra-small);
        font-style: italic;
        font-weight: 600 !important;
        line-height: normal;
        text-transform: uppercase; 
    }
    &:hover {
        background: $feher; 
        border: 1px solid $feher;
        span {
            color: $szoveg;
        }
    }

}

.feliratkozom-button-respo {
    display: flex;
    width: 189px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    flex-shrink: 0; 
    border: 1px solid $kiemelt;
    background: $kiemelt;
    margin-right: 33px;
    
    .span-button {

        color: $elsodleges;
        text-align: center;
        font-size: var(--font-small);
        font-style: italic;
        font-weight: 600 !important;
        line-height: 1;
        text-transform: uppercase; 
    }
    &:hover {
        background: $feher; 
        border: 1px solid $feher;
        .span-button {
            color: $szoveg;
        }
    }

}

.search-button {
    display: flex;
    gap: 10px;
    flex-shrink: 0; 
    border: none;
    background: transparent;
    img {
        width: 19.2px;
        height: 19.2px;
        flex-shrink: 0; 
    }
}

.datum-search-button {
    display: flex;
    gap: 10px;
    flex-shrink: 0; 
    border: none;
    background: transparent;
    padding: 0;
    img {
        width: 56px;
        height: 56px; 
        flex-shrink: 0; 
        transition: all .2s ease-in 0.1s;
    }
    span {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 200 !important;
        line-height: var(--line-height-normal);
        text-decoration-line: underline; 
    }
    &.detail {
        img {
            &:hover {
                transform: scale(1.1);
                transition: all .2s ease-in 0.1s;
            }
        }
    }
}

.social-google {
    display: inline-flex;
    padding: 10px 15px 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    border: 1px solid $szoveg; 
    background-color: transparent;
    span {
        color: $szoveg;
        text-align: center;
        font-size: var(--font-small);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-small);
        text-transform: uppercase; 
    }
}


.social-apple {
    display: inline-flex;
    padding: 10px 20px 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    background: $sotet;
    border: none;
    span {
        color: $feher;
        text-align: center;
        font-family: Urbanist;
        font-size: var(--font-small);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-small);
        text-transform: uppercase; 
    }
}