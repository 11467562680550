.profile-form {
    .modal-title-form {
      color: #464646;
      font-size: var(--font-medium-large);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-medium-large);
    }
    input {
        width: 100%;
        display: flex;
        padding: 11px 10px;
        align-items: center;
        gap: 10px;
        border: none;
        align-self: stretch; 
        background: #FFF; 
        outline: none;
        font-style: normal;
        font-weight: 200 !important;
        font-size: var(--font-normal);
        line-height: var(--line-height-normal);
        color: rgba(37, 38, 38);
    
      & + input {
        margin-top: 10px;
      }
    
      &.error {
        border-color: #c86e6e;
      }
    
      &[readonly],
      &.disabled {
        color: rgba(37, 38, 38, 0.35);
      }
    
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        border-color: #efe2de !important;
        background-color: #efe2de !important;
        -webkit-box-shadow: 0 0 0 50px #efe2de inset;
      }
    }

    .form-message {
      font-style: italic;
      font-weight: 600;

      &--error {
        color: firebrick;
      }
      &--success {
        color: forestgreen;
      }
    }
    
    label {
        margin-bottom: 5px;
        color: #2D2D2D;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 200 !important;
        line-height: var(--line-height-normal);
        span {
            color: #E20000;
            font-size: var(--font-normal);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
        }
    }
  
    textarea {
        width: 100%;
        border: none;
        background: #FFF; 
        outline: none;
        padding: 11px 10px;
        font-style: normal;
        font-weight: 200 !important;
        font-size: var(--font-normal);
        line-height: var(--line-height-normal);
        color: rgba(37, 38, 38);
    }
    
  }
  
  
  .form-check-input:focus::before {
    opacity: 0;
    box-shadow: none;
  }
  .form-check-input[type="checkbox"]:focus::after {
    background-color: transparent;
  }
  .checkbox-div {
    
    input {
        width: 15px;
        height: 15px;
        flex-shrink: 0; 
        border-radius: 3px;
        border: 1px solid  #D4D4D4;
        background: $feher; 
        outline: none;
        padding: 0;
        color: rgba(37, 38, 38);
    
      & + input {
        margin-top: 0;
      }
    
      &.error {
        border-color: #c86e6e;
      }
    
      &[readonly],
      &.disabled {
        color: rgba(37, 38, 38, 0.35);
      }
    
     
      
    
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        border-color: #efe2de !important;
        background-color: #efe2de !important;
        -webkit-box-shadow: 0 0 0 50px #efe2de inset;
      }
    }
    span {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 200 !important;
        line-height: var(--line-height-normal);
    }
    a {
        color: #404040;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
    }
   
  }