.altalanos-modal {
  .modal-dialog {
    max-width: 828px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-body {
    padding: 64px 72px;
    @media (max-width: 811px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    text-align: left; 
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    color: $szoveg;
    font-size: var(--font-medium-large);
    font-style: italic;
    font-weight: 600 !important;
    line-height: var(--line-height-medium-large);
  }
  .modal-description {
      color: $szoveg;
      font-size: var(--font-normal);
      font-style: normal;
      font-weight: 200 !important;
      line-height: var(--line-height-normal);
      margin-bottom: 10px;
  }
  a {
      color: #211551;
      font-size: var(--font-normal);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-normal);
  }
}

.altalanos-modal-two {
  .modal-dialog {
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-body {
    padding: 64px 72px;
    @media (max-width: 811px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    text-align: left; 
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    color: $szoveg;
    font-size: var(--font-medium-large);
    font-style: italic;
    font-weight: 600 !important;
    line-height: var(--line-height-medium-large);
  }
  .modal-description {
    color: $szoveg;
    font-family: Urbanist;
    font-size: var(--font-medium);
    font-style: italic;
    font-weight: 600 !important;
    line-height: var(--line-height-medium);
  }
}

.altalanos-modal-three {
  .modal-dialog {
    max-width: 570px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-body {
    padding: 64px 72px;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    @media (max-width: 811px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    text-align: center; 
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    color: $szoveg;
    font-size: var(--font-medium-large);
    font-style: italic;
    font-weight: 600 !important;
    line-height: var(--line-height-medium-large);
  }
  .modal-description {
    width: 100%;
    color: $szoveg;
    text-align: center; 
    font-size: var(--font-medium);
    font-style: italic;
    font-weight: 600 !important;
    line-height: var(--line-height-medium);
  }
}