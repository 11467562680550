.react-calendar { 
    width: 97%!important;
    height: 53px;
    display: flex !important;
    align-items: center;
    max-width: 97%;
    background-color: $sotet !important;
    color: rgba(255, 255, 255, 0.50)!important;
    border-radius: 0;
    box-shadow: none;
    font-family: Urbanist !important;
    line-height: 1.125em;
    border: none !important;
    @media (max-width: 1300px) {
        width: 100%!important;
        display: block !important;
        height: 112px;
        background-color: transparent !important;
    }
}
.react-calendar__month-view__days__day--weekend {
    color: rgba(255, 255, 255, 0.50) !important;
}
.react-calendar__navigation button:disabled {
    background-color: transparent  !important;
    padding: 0;
    span {
        width: 100% !important;
        text-align: left !important;
        display: block;
        padding-left: 30px;
    }
}
.react-calendar__viewContainer {
    @media (max-width: 1300px) {
        background-color: $sotet !important;
        height: 53px;
    }
    
    
}
.react-calendar__navigation {
    height: 100% !important;
    margin-bottom: 0 !important;
    @media (max-width: 1300px) {
        display: block !important;
        height: 50% !important;
        background-color: $sotet !important;
  margin-bottom: 10px !important;
    }
}
.react-calendar__tile--active {
    background: transparent !important;
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1 !important;
}
.react-calendar__month-view__weekdays {
    display: none !important;
}
.react-calendar__month-view__days {
    display: block !important;
    @media (max-width: 811px) {
        display: flex !important;
        flex-wrap: unset !important;
        overflow: hidden;
    }
}
.react-calendar__navigation__arrow {
    display: none;
}
.react-calendar__month-view__days__day--neighboringMonth {
    display: none !important;
}
.react-calendar__month-view__days__day {
    color: rgba(255, 255, 255, 0.50);
    &:hover {
        //color: $kiemelt !important;
        color: rgba(255, 255, 255, 0.50) !important;
        background-color: transparent !important;
        cursor: default !important;
    }
    &.highlight {
        &:hover {
            color: $kiemelt !important;
            cursor: pointer !important;
            //color: rgba(255, 255, 255, 0.50) !important;
        }  
    }
}
.react-calendar__month-view__days__day.active-highlight {
    cursor: pointer !important;
    &:hover {
        color: $kiemelt !important;
        //color: rgba(255, 255, 255, 0.50) !important;
    }
}

.react-calendar__navigation button {
    color: #fff !important;
    min-width: 150px !important;
    background: none;
    font-size: var(--font-normal) !important;
    margin-right: 30px !important;
    height: 100%;
    font-style: italic !important;
    font-weight: 600 !important;
}
.react-calendar__navigation button:disabled span
{
  display: none;
}
.react-calendar__year-view__months {
    margin-left: -150px;
    flex-direction: column;
    z-index: 3000;
  position: relative;
    @media (max-width: 1300px) {
        margin-left: -130px;
       
    }
}
.react-calendar__year-view {
    position: absolute;
    top: 24px;
    left: 150px;
    .react-calendar__tile {
        padding: 0 !important;
        flex: unset !important;
        height: 53px !important;
        min-height: 100% !important;
        min-width: 150px;
        color: #fff !important;
        min-width: 150px !important;
        background-color: rgba(1, 17, 17, 0.80) !important;
        font-size: var(--font-normal) !important;
        margin-right: 30px !important;
        height: 100%;
        @media (max-width: 1300px) {
            text-align: left;
            padding: 0 0 0 20px !important;
            height: 53px !important;
        }
        
        &:hover {
            background-color: $sotet !important;
            color: $kiemelt !important;
            opacity: 1;
        }
    }
    .react-calendar__tile--now {
        background-color: $sotet !important;
        border-radius: 0;
        font-style: italic !important;
        font-weight: 600 !important;
        color: $kiemelt !important;
       
    }
}
.react-calendar__navigation button:enabled {
    &:hover {
        background-color: $sotet !important;
        span {
            color: $kiemelt !important;
        }
    }
}

.highlight {
    color: #fff !important;
    opacity: 1 !important;
    font-weight: 600 !important;
    font-style: italic !important;
}
.active-highlight {
    color: $kiemelt !important;
    opacity: 1 !important;
    font-weight: 600 !important;
    font-style: italic !important;
}
.react-calendar__tile--now {
    background: $kiemelt !important;
    border-radius: 6px;
    font-weight: 600 !important;
    font-style: italic !important;
    color: #000000 !important;
    &:hover {
        background-color: $kiemelt !important;
        color: #000000 !important;
    }
    &.highlight {
        &:hover {
            color: $kiemelt !important;
            cursor: pointer !important;
            //color: rgba(255, 255, 255, 0.50) !important;
        }  
    }
   
}
.react-calendar button {
    margin: 0 8px 0 0 !important;
    @media (min-width: 500px) and (max-width: 1300px) {
        text-align: left;
        padding-left: 15px !important;
        height:53px;
        min-width: 35px;
    }
    @media (max-width: 499.98px) {
        text-align: left;
        padding-left: 20px !important;
        height:53px;
        flex: 0 0 12.5%!important;
    }
   
}
.react-calendar__tile:disabled {
    display: none !important;
  }