.hirekpage-section {
    min-height: calc(100vh - 617px);
    background: $vilagos;
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    @media (max-width: 1284px) {
        
        padding-left: 20px;
        padding-right: 20px;
    }  
    .hirek-title {
        margin-bottom: var(--within-page-padding);

        span {
            color: $sotet;
            font-size: var(--font-medium-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium-large);
        }
    }
    .hirek-row {
        margin-left: -12px !important;
        margin-right: -12px !important;
        row-gap: 24px;

        .hirek-col {
          display: flex;
          align-items: center;
          justify-content: center;
            @media (max-width: 811px) {
                padding-left: 10px!important;
                padding-right: 10px!important;
            } 
            .hirek-card-div {
                width: 100%;
                max-width: 320px;
                height: 380px;
                flex-shrink: 0; 
                background: $feher; 
                box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.16);
                @media (max-width: 811px) {
                    height: 450px;
                    padding-bottom: 20px;
                } 
                cursor: pointer;
                .image-div {
                        
                    flex-shrink: 0; 
                    //margin-right: 24px;
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                    &:hover {
                        filter:  contrast(50%);
                    }
                }
                &:hover {
                    .title-div {
                        p {
                            color: $kiemelt;
                        }
                      
                    }
                }
                .title-div {
                    padding: 20px 25px 0 25px;
                    p {
                        color: $sotet;
                        font-size: var(--font-medium);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-medium);
                        margin-bottom: 15px !important;
                        min-height: 56px;
                        max-height: 56px;
                        overflow: hidden;

                        @media (max-width: 811px) {
                            min-height: unset;
                            max-height: 56px;
                        } 
                    }
                    span {
                        color: $szoveg;
                        font-size: var(--font-small);
                        font-style: italic;
                        font-weight: 600 !important;
                        line-height: var(--line-height-small);
                    }
                }
                .text-div {
                    padding: 15px 25px 0 25px;

                    span {
                        color: $szoveg;
                        font-size: var(--font-normal);
                        font-style: normal;
                        font-weight: 200 !important;
                        line-height: var(--line-height-normal);
                        max-height: calc(2 * var(--line-height-normal));
                        overflow: hidden;
                        display: flex;
                    }
                }
            }
        }
    }
    .loadmore-div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: var(--within-section-padding);

        button {
            span {
                text-transform: initial; 
            }
            &:hover {
                background: $kiemelt; 
                transition: 0.6s ease-in-out;
            }
        }
        svg {
            margin-top: -4px;
            margin-left: 4px;
        }
    }
    
}