
.more-carousel {
    .carousel-container {
        display: flex;
        overflow: hidden !important;
        justify-content: left;
        max-width: 100vw;
        margin: 0;
        position: relative;
        z-index: 1000 !important;
        
        .react-multi-carousel-item {
            transform-style: unset;
            display: flex;
            align-items: center;
            padding: 0;
            .image-items {
                width: 100%;
                .gallery-image {
                    width: 100%;
                    //height: 100%;
                    height: 212.133px;
                    object-fit: cover;
                }
                .gallery-image-one {
                    width: 100%;
                    //height: 212.133px;
                    //max-height: 212.133px;
                    //object-fit: cover;
                }
            }
           
        }
        /*

        .react-multiple-carousel__arrow {
            top: 0;
            //transform: translateY(69px);
            border: none;
            border-radius: 0;
            flex-grow: 0;
            background-color: rgba(0,0,0,0.7);
            z-index: 10;
            margin: 0;
            cursor: pointer;
            font-size: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            line-height: 0;
            transition: background-color 150ms ease-in-out;
            width: 80px;
            height: 100%;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
            @media (min-width: 1600px) and (max-width: 1919.98px) {
                width: 70px;
            }
            @media (min-width: 1400px) and (max-width: 1599.98px) {
                width: 60px;
            }
            @media (min-width: 1250px) and (max-width: 1399.98px) {
                width: 55px;
            }
            @media (min-width: 1080px) and (max-width: 1249.98px) {
                width: 50px;
            }
        }

        .react-multiple-carousel__arrow--right {
            
            right: 0;
            &::before {
                background-image: url('../../media/icons/next.png');
                background-size: 16px 35px;
                display: inline-block;
                height: 35px;
                width: 16px;
                content:"";
                top: -10px;
                @media (min-width: 1600px) and (max-width: 1919.98px) {
                    transform: scale(.9);
                }
                @media (min-width: 1400px) and (max-width: 1599.98px) {
                    transform: scale(.8);
                }
                @media (min-width: 1250px) and (max-width: 1399.98px) {
                    transform: scale(.7);
                }
                @media (min-width: 1080px) and (max-width: 1249.98px) {
                    transform: scale(.6);
                }
            }
            
        }

       .react-multiple-carousel__arrow--left {
            
            left: 0;
            &::before {
                background-image: url('../../media/icons/prev.png');
                background-size: 16px 35px;
                display: inline-block;
                height: 35px;
                width: 16px;
                content:"";
                top: -10px;
                @media (min-width: 1600px) and (max-width: 1919.98px) {
                    transform: scale(.9);
                }
                @media (min-width: 1400px) and (max-width: 1599.98px) {
                    transform: scale(.8);
                }
                @media (min-width: 1250px) and (max-width: 1399.98px) {
                    transform: scale(.7);
                }
                @media (min-width: 1080px) and (max-width: 1249.98px) {
                    transform: scale(.6);
                }  
            }
        }

        */
        
    }
    .react-multi-carousel-dot-list {
        position: absolute !important;
        display: flex !important;
        bottom: -45px !important;
        left: 50% !important;
        transform: translateX(-50%);
        right: unset!important;
        justify-content: flex-end !important;
        padding: 0 !important;
        margin: 0;
        list-style: none !important;
        text-align: center !important;
        height: 38px;
        @media (min-width: 1600px) and (max-width: 1919.98px) {
            right: 110px !important;
        }
        @media (min-width: 1300px) and (max-width: 1599.98px) {
            right: 110px !important;
        }
        @media (min-width: 1080px) and (max-width: 1299.98px) {
            right: 110px !important;
        }
        li {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    
}

.button-container {
    position: absolute;
    width: 1920px !important;
    left: -181.5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 25px;
    .custom-button-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-button {
            border: none;
            box-shadow: none;
            background-color: transparent;
            padding: 40px 40px;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
            img {
                width: 34.25px;
                height: 69.7px;
            }
            &.firststep {
                opacity: 0;
                &:hover {
                    opacity: 0;
                    cursor:unset;
                }
                
            }
        }
        .right-button {
            border: none;
            box-shadow: nonet;
            background-color: transparent;
            padding: 40px 40px;
            opacity: 0.5;
            transition: all .3s ease-in 0.2s;
            &:hover {
                opacity: 1;
                transition: all .3s ease-in 0.2s;
            }
            img {
                width: 34.25px;
                height: 69.7px;
            }

        }
    }
    @media (min-width: 1080px) and (max-width: 1599.98px) {
        padding: 0 10px;
        left: -110px;
        .custom-button-group {
            .left-button {
                padding: 20px 20px;
                img {
                    transform: scale(0.8);
                }
            }
            .right-button {
                padding: 20px 20px;
                img {
                    transform: scale(0.8);
                }
    
            }
        }
    }
}

.slider-carousel {
  &--blurred {
    filter: blur(7px);
    transition: filter 500ms;
  }

    .react-multi-carousel-list {
        // max-height: 926px;
        height: auto;
    }

    .slider-div, .react-multi-carousel-track {
        min-height: 700px;
        max-height: min(calc(100vh - 84px ), 48.23vw);

      @media (max-width: 811px) {
        min-height: unset;
        max-height: unset;
      }
    }

    .react-multiple-carousel__arrow {
        top: 0;
        //transform: translateY(69px);
        border: none;
        border-radius: 0;
        flex-grow: 0;
        background-color: transparent;
        z-index: 10;
        margin: 0;
        cursor: pointer;
        font-size: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 0;
        transition: background-color 150ms ease-in-out;
        width: 120px;
        height: 100%;
        opacity: 0;
        &:hover {
            opacity: 1;
            background-color: transparent;
        }
        @media (min-width: 1600px) and (max-width: 1919.98px) {
            width: 70px;
        }
        @media (min-width: 1400px) and (max-width: 1599.98px) {
            width: 60px;
        }
        @media (min-width: 1250px) and (max-width: 1399.98px) {
            width: 55px;
        }
        @media (min-width: 1080px) and (max-width: 1249.98px) {
            width: 50px;
        }
    }

    .react-multiple-carousel__arrow--right {
        
        right: 0;
        &::before {
            background-image: url('../../media/icons/next_3.png');
            background-size: 16px 35px;
            display: inline-block;
            height: 35px;
            width: 16px;
            content:"";
            top: -10px;
            @media (min-width: 1600px) and (max-width: 1919.98px) {
                transform: scale(.9);
            }
            @media (min-width: 1400px) and (max-width: 1599.98px) {
                transform: scale(.8);
            }
            @media (min-width: 1250px) and (max-width: 1399.98px) {
                transform: scale(.7);
            }
            @media (min-width: 1080px) and (max-width: 1249.98px) {
                transform: scale(.6);
            }
        }
        
    }

   .react-multiple-carousel__arrow--left {
        
        left: 0;
        &::before {
            background-image: url('../../media/icons/prev_3.png');
            background-size: 16px 35px;
            display: inline-block;
            height: 35px;
            width: 16px;
            content:"";
            top: -10px;
            @media (min-width: 1600px) and (max-width: 1919.98px) {
                transform: scale(.9);
            }
            @media (min-width: 1400px) and (max-width: 1599.98px) {
                transform: scale(.8);
            }
            @media (min-width: 1250px) and (max-width: 1399.98px) {
                transform: scale(.7);
            }
            @media (min-width: 1080px) and (max-width: 1249.98px) {
                transform: scale(.6);
            }  
        }
    }
    .react-multi-carousel-dot-list {
        bottom: unset;
        top: 10px;
    }
    .react-multi-carousel-dot button {
        transition: opacity .25s ease-in;
        border: none!important;
        box-shadow: none;
        background-color: #A29898;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        opacity: 0.9;
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
      }
      .react-multi-carousel-dot--active button {
        opacity: 1;
        height: 10px;
        width: 10px;
        background: $kiemelt;
        border-radius: 50%;
        border: none!important;
      }
}
