// @import url('https://fonts.googleapis.com/css?family=Urbanist:wght@300;600');
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English:wght@100;200;300;400;500;700&display=swap');
@import url("https://unpkg.com/leaflet@1.9.4/dist/leaflet.css");

.noselect {
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

:root {
  --box-font: "Urbanist";
}

body {
  background: #ffffff;
  min-height: 100vh;
  overflow-x: hidden !important;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: var(--box-font) !important;
  font-synthesis: none !important;

  button {
    font-family: var(--box-font) !important;
  }
}

html {
  height: 100vh;
  scroll-behavior: smooth;
}

b,
strong {
  font-style: italic;
  font-weight: 600 !important;
}

$elsodleges: #211551;
$kiemelt: var(--box-bg);
$szoveg: #404040;
$vilagos: #f8faff;
$sotet: #011111;
$feher: #ffffff;

//pages
@import "pages/homepage.scss";
@import "pages/vertesagorajapage.scss";
@import "pages/felnottkepzespage.scss";
@import "pages/kapcsolatpage.scss";
@import "pages/hirekpage.scss";
@import "pages/hirekdetailpage.scss";
@import "pages/programdetailpage.scss";
@import "pages/galeriakategoriapage.scss";
@import "pages/hirlevelfeliratkozaspage.scss";
@import "pages/loginpage.scss";
@import "header/navbar.scss";
@import "header/accessibilitynavbar.scss";
@import "buttons/buttons.scss";
@import "footer/footer.scss";
@import "calendar/calendar.scss";
@import "calendar/reactcalendar.scss";
@import "form/profileform.scss";
@import "form/hirlevelform.scss";
@import "form/single-image-uploader.scss";
@import "modals/modal.scss";
@import "carousel/carousel.scss";

@keyframes spin {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes spinner-open {
  0% {
    margin-top: 0;
    opacity: 0;
  }

  50% {
    margin-top: 0;
  }

  75% {
    opacity: 0;
  }

  85% {
    margin-top: 48px;
  }

  100% {
    opacity: 1;
    margin-top: 48px;
  }
}

:root {
  --font-extra-small: 12px;
  --font-small: 14px;
  --font-normal: 16px;
  --font-medium: 22px;
  --font-medium-large: 26px;
  --font-large: 32px;
  --font-extra-large: 38px;

  --line-height-extra-small: normal;
  --line-height-small: normal;
  --line-height-normal: 26px;
  --line-height-medium: 28px;
  --line-height-medium-large: 32px;
  --line-height-large: 38px;
  --line-height-extra-large: 46px;

  --section-padding: 64px;
  --within-section-padding: 40px;
  --within-page-padding: 34px;
  --within-page-small-padding: 24px;
  --page-padding-top: 40px;
  --page-padding-bottom: 100px;

  @media (max-width: 811px) {
    --font-small: 14px;
    --font-normal: 16px;
    --font-medium: 20px;
    --font-medium-large: 24px;
    --font-large: 28px;

    --section-padding: 40px;
    --within-section-padding: 24px;
    --within-page-padding: 24px;
    --within-page-small-padding: 16px;
    --page-padding-top: 28px;
    --page-padding-bottom: 40px;
  }
}

.scrollbar-container {
  height: 100vh !important;
}

.modal-header {
  display: flex;
  flex-direction: column;
}

.modal {
  z-index: 3000;
}

.navigate-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-top: var(--within-page-padding);
  margin-bottom: var(--within-page-padding);

  button {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $szoveg;
    font-size: var(--font-small);
    font-style: italic;
    font-weight: 600 !important;
    line-height: var(--line-height-small);
    cursor: pointer;

    &:hover {
      color: $kiemelt !important;
    }

    &:first-child {
      padding-left: 0;
    }
  }
  img {
    margin: 0 10px;
  }
}

h3 {
  color: #000;
  font-size: var(--font-medium-large);
  font-style: normal;
  font-weight: 600 !important;
  line-height: var(--line-height-medium-large);
  margin-bottom: var(--within-page-padding);
}

#map-keret > div:nth-child(1) > div:nth-child(1) {
  height: 95% !important;
}

.layout {
  @media (max-width: 811px) {
    padding-top: 60px;
  }

  &--loading {
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.loading {
  background-color: white;
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  transition: opacity 0ms 0ms;
  width: 100%;
  z-index: 100000;

  img {
    height: 50px;
  }

  &--hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms 250ms;
  }

  .spinner {
    margin-top: 0;

    div {
      animation: spinner-open 3s 1 both, spin 800ms infinite linear;

      &:nth-child(1) {
        border-color: grey;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        width: 24px;
        height: 24px;
        position: relative;

        &::after {
          background-color: white;
          content: "";
          position: absolute;
          top: -2px;
          left: -2px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.blurhash-image-container {
  position: relative;

  .blurhash-real-image,
  .slider-image {
    height: auto;
    width: 100%;
    opacity: 0;

    transition: opacity 1000ms;
  }

  .blurhash {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;

    transition: opacity 1000ms;
  }

  &--hide {
    .blurhash-real-image,
    .slider-image {
      opacity: 1;
      pointer-events: all;
    }

    .blurhash {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.editorDemoTable {
  transition: background-color 333ms;

  img {
    transition: filter 333ms;
  }

  &:hover {
    background-color: var(--box-bg) !important;

    img {
      filter: brightness(0);
    }
  }

  &.responsive {
    img {
      @media (max-width: 811px) {
        width: 33vw;
        height: auto;
      }
    }
  }
}

.room-pdf-viewer-container,
.pdf-viewer-container {
  background-color: #00000080;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;

  display: flex;
  align-items: center;
  justify-content: center;
}

ul[data-menubar-submenu-list] {
  div {
    width: 100%;

    li {
      justify-content: flex-start;
      width: 100%;

      > button {
        text-align: left;
      }
    }
  }
}

.profile-form {
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  &--tbkartya {
    margin-left: 0;
    margin-right: 0;
  }
}
