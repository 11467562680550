.login-page {
  background-color: #E5E9F4;
  padding-top: 128px;

  .login-form {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    width: 492px;

    @media (max-width: 811px) {
      width: 100%;
    }

    &__title {
      color: #404040;
      font-size: 26px;
      font-style: italic;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 22px;
    }

    &__description {
      color: #2D2D2D;
      font-size: 16px;
      line-height: 26px;
      margin-top: 14px;
      margin-bottom: 20px;
      width: 404px;

      @media (max-width: 811px) {
        width: 100%;
      }
    }

    &__buttons {
      display: flex;
      gap: 10px;
    }

    .form-field {
      display: flex;
      flex-direction: column;

      label {
        color: #2D2D2D;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 5px;
      }

      input {
        border: none;
        background-color: #FFFFFF;
        padding: 11px 10px;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 7px;

        &::placeholder {
          color: #BDBDBD;
        }
      }
    }

    button {
      background-color: #211551;
      border: none;
      color: #FFF;
      font-size: 14px;
      font-style: italic;
      font-weight: 800;
      line-height: normal;
      margin-top: 22px;
      padding: 10px 24px;
      text-transform: uppercase;
    }
  }
  .login-footer {
    border-top: 1px solid #B9B9B9;
    display: flex;
    gap: 24px;
    padding-top: 22px;
    padding-bottom: 77px;

    @media (max-width: 811px) {
      flex-direction: column;
    }

    .social-button {
      align-items: center;
      border: 1px solid #404040;
      background-color: #FFF;
      cursor: pointer;
      display: flex;
      padding: 15px 35px;
      width: 100%;

      &__icon {
        display: flex;
      }

      img {
        height: 20px;
        margin-right: 16.5px;
      }

      &__caption {
        color: #404040;
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        white-space: nowrap;
      }
    }
  }
}