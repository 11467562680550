* {
    box-sizing: border-box;
  }
  
  .multilevel-navbar {
    display: flex;
    justify-content: space-between;

    img[alt="tovabb"] {
      height: 7px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 42px;
      width: max-content;
    }
    
    li {
      //padding: 1rem;
      position: relative;
      height: 42px;
      width: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background: transparent;
        border: none;
        box-shadow: none;
        margin-right: 26.4px;
        padding: 0 !important;
        color: #011111;
        text-align: right;
        font-size: var(--font-small);
        font-style: italic;
        font-weight: 600;
        line-height: var(--line-height-small);
        position: relative;
        &:hover {
            color: $kiemelt;
        }
     
        &.active {
            &::before {
                    
              position: absolute;
              margin: auto;
              z-index: 1;
              content: "";
              bottom: -2px;
              left: 0;
              height: 2px;
              width: 100%;
              border-radius: 1px;
              background-color: $kiemelt;
            }
        }
      }
      @media (min-width: 811px) and (max-width: 1000px) {
        //height: 26px;
        button {
          margin-right: 20px;
        }
      }
    }
    
    .menubar.horizontal {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      width: 100%;

      > div:nth-child(2) {
        > li {
          height: 60px;
          @media (max-width: 1284px) {
            height: 60px;
          }

          > a button {
            margin-right: 0;
          }

          > button, > a button {
            &:hover, &[aria-expanded="true"] {
              color: inherit;
              &::after {
                content: "";
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                border-radius: 1px;
                background-color: $kiemelt;
              }
            }

            &.jegyvasarlas-button {
              &::after {
                display: none;
              }
            }
          }

          > button.search-button {
            &:hover, &[aria-expanded="true"] {
              color: inherit;

              &::after {
                display: none;
              }
            }
          }

          > li {
            height: 60px;
            @media (max-width: 1284px) {
              height: 65px;
            }

            > button, > a button {
              &:hover, &[aria-expanded="true"] {
                color: inherit;
                &::after {
                  content: "";
                  position: absolute;
                  bottom: -2px;
                  left: 0;
                  width: 100%;
                  height: 2px;
                  border-radius: 1px;
                  background-color: $kiemelt;
                }
              }
            }
          }
        }

        > div {
          > li {
            height: 60px;
            @media (max-width: 1284px) {
              height: 60px;
            }

            > a button {
              margin-right: 0;
            }

            > button, > a button {
              &:hover, &[aria-expanded="true"] {
                color: inherit;
                &::after {
                  content: "";
                  position: absolute;
                  bottom: -2px;
                  left: 0;
                  width: 100%;
                  height: 2px;
                  border-radius: 1px;
                  background-color: $kiemelt;
                }
              }
            }
          }
        }
      }
    }
    
    .menubar.horizontal > li {
      //flex: 1;
      width: fit-content;
    }
    
    .menubar.vertical {
      display: flex;
      flex-flow: column;
      width: fit-content;
    }
    
    .submenu {
      background: $vilagos;
      border-radius: 0;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10); 
      position: absolute;
      
      inset: 0px auto auto 10px;
      padding: 3px 0 16px 15px;
      transform: translate(-24px, 70px) !important;
      width: max-content;
      z-index: 1;
      margin-top: -10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: max-content;

      @media (max-width: 1284px) {
        transform: translate(-24px, 75px) !important;
      }
    }
    
    .submenu[hidden=""] {
      pointer-events: none;
    }
    
    .submenu .submenu {
      background: $vilagos; 
      border-radius: 0;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10); 
      position: absolute;
      
      inset: 0px auto auto 0px;
      padding: 3px 0 16px 15px;
      transform: translate(-24px, 47px) !important;
      width: max-content;
      z-index: 1;
      //margin-top: -20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: max-content;
      left: calc(100% + 25px);
      top: -41px;
     
    }
    
    .menubar.vertical .submenu {
      padding: 0;
      position: absolute;
      left: 100%;
      top: 0;
      width: fit-content;
    }
    .kortars-logo {
      color: #000;
      font-family: "IM FELL English";
      font-size: var(--font-medium);
      font-style: normal;
      font-weight: 200 !important;
      line-height: var(--line-height-medium);
      margin-left: 9px;
    }
  }

  .sidenav-bottom-respo {
    position: fixed;
    width: 100%;
    top: 0;
    
    height: 65px;
    flex-shrink: 0; 
    z-index: 2520;
    padding: 0 20px;
    background: #ffffff;
    /* Árnyék */
    box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.08); 
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .sidenav {
    z-index: 2520;
    width: 100%;
    top: 92px;
    @media (max-width: 499.98px) {
      top: 62px;
    }
    background: #F8FAFF;
    /* Árnyék 3 */
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10); 
    height: auto;
    .sidenav-absolute {
      position: absolute;
      height: 100%;
    }
    .menu-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      
    }
    .submenu-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding-left: 24px;
      margin-top: 16px;
      height: 40px;
      span {
        color: #404040;
        font-size: var(--font-small);
        font-style: normal;
        font-weight: 400;
        line-height: var(--line-height-small);
      }
      img {
        margin-right: 11px;
      }
    }
    .sidenav-link {
      color: #404040;
      font-size: 16px;
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-normal);
      height: 40px;
      padding: 7px 24px; /* 150% */ 
      &:hover {
        
          color:  $kiemelt !important; 
        
      }
    }
    .scrollbar-container {
      height: auto !important;
    }
  }

  .sidenav-collapse {
    height: auto;
  }
  .sidenav-menu, .sidenav-collapse {
    padding: 0 ;
  }

.sidenav-menu {
  padding-bottom: 15px;
}
  
 
  