.slide-section {
  .blur-layer {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: backdrop-filter 333ms, -webkit-backdrop-filter 333ms;

    &--blur {
      backdrop-filter: blur(7px);
      -webkit-backdrop-filter: blur(7px);
    }
  }
  .first-div {
    position: relative;
    .slider-div {
      position: relative;
      .slider-image {
        width: 100%;
        // height: 926px;
        object-fit: cover;
        @media (max-width: 810.98px) {
          object-fit: cover;
          //min-height: 428px;
        }
      }
      &--position-1 {
        .felirat {
          right: calc(50% - 504px);
          top: 52.97%;
        }
      }

      &--position-2 {
        .felirat {
          right: calc(50% - 504px);
          top: 14.86%;
        }
      }

      &--position-3 {
        .felirat {
          left: calc(50% - 504px);
          top: 14.86%;
        }
      }

      .felirat {
        position: absolute;
        z-index: 250;
        @media (max-width: 811px) {
          display: none;
        }
        .felirat-datum {
          position: absolute;
          top: -24px;
          right: 50px;
          display: inline-flex;
          padding: 4px 28.8px;
          justify-content: center;
          align-items: center;
          gap: 15px;
          background: $sotet;
          span {
            color: $feher;
            font-size: var(--font-medium);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium);
          }
        }
        .felirat-szoveg {
          display: flex;
          max-width: 618px;
          padding: 11.4px 32px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 9px;
          flex-shrink: 0;
          background: $kiemelt;
          .title {
            width: 440px;
            font-size: var(--font-extra-large);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-extra-large);
            color: $sotet;
          }
          .description {
            width: 440px;
            font-size: var(--font-medium);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium);
            color: $sotet;
          }
          &:hover {
            background: $elsodleges;
            .title {
              color: $feher;
            }
          }
        }
      }
    }
    .calendar-div {
      position: absolute;
      display: flex;
      width: 510px;
      bottom: 16%;
      left: calc(50% - 504px);
      transform: translate(0, -50%);
      z-index: 250;
      .datum-search-button {
        margin-left: -30px;
        margin-top: -2px;
        &:hover {
          transform: scale(1.1);
        }
      }
      .navigation-button {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 158px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-size: var(--font-normal);
        line-height: var(--line-height-normal);
      }
      .react-calendar {
        width: 97% !important;
        height: 53px;
        display: flex !important;
        align-items: center;
        max-width: 97%;
        background-color: $sotet !important;
        color: rgba(255, 255, 255, 0.5) !important;
        border-radius: 0;
        box-shadow: none;
        font-family: "Urbanist-Bold" !important;
        font-size: var(--font-normal);
        font-style: normal;
        line-height: var(--line-height-normal);
        border: none !important;
      }
      .react-calendar__viewContainer {
        width: 60%;
      }
      .react-calendar__month-view__days__day--weekend {
        color: rgba(255, 255, 255, 0.5) !important;
        &:hover {
          //color: $kiemelt !important;
          color: rgba(255, 255, 255, 0.5) !important;
        }
        &.highlight {
          &:hover {
            color: $kiemelt !important;
            //color: rgba(255, 255, 255, 0.50) !important;
          }
        }
      }

      .react-calendar__navigation button:disabled {
        background-color: transparent !important;
        padding: 0;
        span {
          width: 100% !important;
          text-align: left !important;
          display: block;
          padding-left: 30px;
        }
      }
      .react-calendar__navigation {
        height: 100% !important;
        margin-bottom: 0 !important;
      }
      .react-calendar__tile--active {
        background: transparent !important;
        //color: #fff !important;
        opacity: 1 !important;
        overflow: unset !important;
        position: relative;
      }

      .react-calendar__month-view__weekdays {
        display: none !important;
      }
      .react-calendar__month-view__days {
        display: flex !important;
        flex-wrap: unset !important;
      }
      .react-calendar__navigation__arrow {
        display: none;
      }
      .react-calendar__month-view__days__day--neighboringMonth {
        display: flex !important;
      }
      .react-calendar__month-view__days__day {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: rgba(255, 255, 255, 0.5);
        &.highlight {
          &:hover {
            color: $kiemelt !important;
          }
        }
      }
      .react-calendar__navigation {
        pointer-events: none;
      }
      .react-calendar__navigation button {
        color: #fff !important;
        min-width: 150px !important;
        background: none;
        font-size: var(--font-normal) !important;
        margin-right: 30px !important;
        height: 100%;
      }
      .react-calendar__navigation button:disabled span {
        display: none;
      }
      .react-calendar__year-view__months {
        margin-left: -150px;
        flex-direction: column;
      }
      .react-calendar__tile {
        abbr {
          font-size: 16px;
        }
      }
      .react-calendar__tile--now {
        background: transparent !important;
        border-radius: 6px;
        font-weight: 200 !important;
        color: rgba(255, 255, 255, 0.5) !important;
      }

      .highlight {
        color: #fff !important;
        opacity: 1 !important;
        font-weight: 600 !important;
        font-style: italic;
      }
      .active-highlight {
        color: $kiemelt !important;
        opacity: 1 !important;
        font-weight: 600 !important;
        font-style: italic;
        &::after {
          position: absolute;
          margin: auto;
          z-index: 1;
          content: "";
          top: -48px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid rgba(255, 255, 255, 0.9);
          translate: 0 -0.5px;

          @media (max-width: 1300px) {
            top: -45px;
          }
        }
      }
      .react-calendar button {
        margin: 0 8px 0 0 !important;
      }
      .react-calendar__tile:disabled {
        display: none !important;
      }
      .react-calendar__year-view {
        position: absolute;
        top: 24px;
        left: 150px;
        .react-calendar__tile {
          padding: 0 !important;
          flex: unset !important;
          height: 53px !important;
          min-height: 100% !important;
          min-width: 150px;
          color: #fff !important;
          min-width: 150px !important;
          background-color: rgba(1, 17, 17, 0.8) !important;
          font-size: var(--font-normal) !important;
          margin-right: 30px !important;
          height: 100%;
          &:hover {
            background-color: $sotet !important;
            color: $kiemelt !important;
            opacity: 1;
          }
        }
        .react-calendar__tile--now {
          background-color: $sotet !important;
          border-radius: 0;
          font-weight: 600 !important;
          font-style: italic;
          color: $kiemelt !important;
        }
      }
      .program-div {
        position: absolute;
        .active-program-div {
          position: absolute;
          width: 404px;
          height: 25px;
          top: -71px;
          left: 65px;
          background: rgba(255, 255, 255, 0.9);
        }
        .active-program-card {
          position: absolute;
          top: -465px;
          left: 65px;
          width: 404px;
          height: 386px;
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.16);

          .navigate-div {
            cursor: pointer;
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 70%;
            z-index: 1500;
          }
          .active-program-background-image {
            width: 404px;
            height: 212px;
          }
          .ikon-div {
            position: absolute;
            top: 15px;
            left: 18px;

            cursor: pointer;
            img {
              &:hover {
                transform: scale(1.1);
              }
            }
          }
          .bezaras-div {
            background-color: var(--box-bg);
            border-radius: 50%;
            position: absolute;
            top: -19px;
            right: -19px;
            cursor: pointer;
          }
          .text-div {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 174px;
            padding: 0 39px;

            .time {
              position: absolute;
              left: 53px;
              top: -19px;
              width: unset;
              padding-left: 15px;
              padding-right: 15px;
              height: 38px;
              flex-shrink: 0;
              background: $kiemelt;
              display: flex;
              align-items: center;
              justify-content: center;
              .time-text {
                color: $sotet;
                text-align: center;
                font-size: var(--font-medium);
                font-style: italic;
                font-weight: 600 !important;
                line-height: var(--line-height-medium);
              }
            }
            p {
              color: $sotet;
              text-align: center;
              font-size: var(--font-large);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-large);
              margin-top: 15px;

              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            span {
              color: $szoveg;
              text-align: center;
              font-size: var(--font-normal);
              font-style: normal;
              font-weight: 200 !important;
              line-height: var(--line-height-normal);
            }
          }
        }
      }
    }
  }
}

.kovetkezo-programok-section {
  background: $vilagos;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);

  &.events-page {
    padding-top: var(--page-padding-top);
    padding-bottom: var(--page-padding-bottom);

    .program-title {
      margin-bottom: var(--within-page-padding);

      span {
        font-size: var(--font-medium-large);
        line-height: var(--line-height-medium-large);
      }
    }

    .programok-list-section {
      margin-bottom: var(--within-section-padding);

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 810px) {
        margin-bottom: 40px;
      }
    }

    .programok-row {
      margin-bottom: 0;
    }

    .loadmore-div {
      margin-top: var(--within-section-padding);
    }
  }

  .react-multiple-carousel__arrow--right {
    background: transparent !important;
    top: 22.5%;
    right: 20px;
  }

  .react-multiple-carousel__arrow--left {
    background: transparent !important;
    top: 22.5%;
    left: 20px;
  }

  .react-multi-carousel-list {
    overflow-x: hidden !important;
    overflow-y: visible !important;
    padding-bottom: 54.39px;
    margin-bottom: -54.39px;
  }

  .datum-search-div {
    position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 1284px) {
      padding: 0 20px;
    }
    .datum-search-button {
      position: absolute;
      right: 0;
      @media (max-width: 1284px) {
        right: 20px;
      }
    }
    .form-outline {
      width: 97%;
      height: 53px;
      @media (max-width: 810.98px) {
        height: 56px;
        width: calc(100% - 28px);
      }
      .form-control {
        min-height: 53px;
        padding-top: 0.32rem;
        padding-bottom: 0.32rem;
        padding-left: 30px;
        padding-right: 0.75rem;
        border: 1px solid #bdbdbd;
        border-radius: 0;
        background: $sotet;
        transition: all 0.2s linear;
        color: $vilagos;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 200 !important;
        line-height: var(--line-height-normal);
        @media (max-width: 810.98px) {
          height: 56px;
        }
      }
      .form-control ~ .form-label {
        position: absolute;
        top: 50%;
        left: 0;
        padding-top: 0;
        padding-left: 30px;
        transform: translateY(-50%);
        margin-bottom: 0;
        color: $vilagos;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 200 !important;
        line-height: var(--line-height-normal);
      }
      .form-control:focus ~ .form-label {
        display: none;
      }
      .form-control.active ~ .form-label {
        display: none;
      }
      .form-control ~ .form-notch {
        display: none !important;
      }
    }
  }
  .program-title {
    margin-bottom: var(--within-section-padding);

    span {
      color: $sotet;
      font-size: var(--font-large);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-large);
    }
    &--search {
      padding-top: 60px;
      padding-left: 5px;
      span {
        font-size: var(--font-medium);
        line-height: var(--line-height-medium);
      }
    }
    @media (max-width: 810.98px) {
      padding-left: 20px;
      span {
        color: $sotet;
        font-style: italic;
        font-weight: 600 !important;
      }
    }
    @media (min-width: 811px) and (max-width: 1284px) {
      padding-left: 20px;
    }
  }

  .programok-row {
    margin-bottom: var(--within-section-padding);
    margin-left: -12px !important;
    margin-right: -12px !important;
    row-gap: 24px;

    @media (max-width: 1284px) {
      padding-left: 0;
      margin-left: 0 !important;
      padding-right: 0;
      margin-right: 0 !important;
    }

    .programok-col {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1284px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }

      @media (max-width: 811px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }

      .program-card-div {
        position: relative;
        width: 100%;
        max-width: 320px;
        height: 370.865px;
        flex-shrink: 0;
        background: $feher;
        box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.16);
        cursor: pointer;

        @media (max-width: 1284px) {
          height: unset;
        }

        @media (max-width: 811px) {
          max-width: unset;
        }

        &:hover {
          .image-div {
            .overlay {
              opacity: 0.4;
              transition: 0.6s;
            }
          }
          .datum-div {
            background: $elsodleges;
            transition: 0.8s;
            span {
              color: $feher;
              transition: 0.8s;
            }
          }
        }
        .image-div {
          position: relative;
          background-size: auto 100%;
          background-repeat: no-repeat;
          background-position: center top;
          height: 180px;
          max-width: 320px;

          @media (max-width: 811px) {
            max-width: unset;
          }

          .overlay {
            background: rgb(0, 0, 0);
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: 0.6s;
          }
          .program-ikon-image {
            position: absolute;
            left: 11.89px;
            top: 14.28px;
            width: 49.92px;
            height: 49.92px;
            flex-shrink: 0;
            z-index: 1000;
            transition: scale 333ms ease-in-out;

            &:hover {
              scale: 1.2;
            }
          }

          @media (max-width: 1284px) {
            height: unset;
          }
        }
        .datum-div {
          width: 100%;
          max-width: 320px;
          height: 40.4px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $kiemelt;
          transition: 0.8s;

          @media (max-width: 811px) {
            max-width: unset;
          }

          span {
            color: $sotet;
            text-align: center;
            font-size: var(--font-medium);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium);
            transition: 0.8s;
          }
        }
        .text-div {
          height: 150px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 38px;

          p {
            color: $sotet;
            text-align: center;
            font-size: var(--font-medium);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium);
            margin-bottom: 18px !important;

            @media (max-width: 1284px) {
              max-height: calc(3 * var(--line-height-medium));
              overflow: hidden;
            }
          }
          span {
            color: $szoveg;
            text-align: center;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
          }
        }
      }
    }
  }

  .loadmore-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 810.98px) {
      padding-right: 20px;
    }
    @media (min-width: 811px) and (max-width: 1284px) {
      padding-right: 20px;
    }
    button {
      z-index: 100;
      span {
        text-transform: initial;
      }
      &:hover {
        background: $kiemelt;
        transition: 0.6s ease-in-out;
      }
    }
    svg {
      margin-top: -4px;
      margin-left: 4px;
    }
  }
  .loadmore-div-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 12px;
    padding-left: 1.5rem;
    margin-bottom: 85px;
    button {
      margin-top: 15px;
      span {
        text-transform: initial;
      }
      &:hover {
        background: $kiemelt;
        transition: 0.6s ease-in-out;
      }
    }
    svg {
      margin-top: -4px;
      margin-left: 4px;
    }
  }
  .navigate-div {
    @media (max-width: 810.98px) {
      margin-bottom: 24px;
      margin-top: 24px;
      padding: 0 20px;
    }
  }
}

.programfuzet-section {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);

  @media (max-width: 810.98px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .programfuzet-div {
    background-image: url("../../media/images/programfuzet hatter.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    aspect-ratio: 1920 / 466;
    @media (max-width: 810.98px) {
      padding-top: var(--section-padding);
      padding-bottom: var(--section-padding);
      background-size: auto 100%;
    }
    .programfuzet-text {
      padding-left: 84.82px;
      @media (max-width: 810.98px) {
        padding: 0 20px;
      }
      p {
        color: $feher;
        text-align: left;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
        margin-bottom: 16.4px !important;
      }
      .description {
        color: $feher;
        text-align: left;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
      }
      button {
        margin-top: 16.4px;
      }
    }
    img {
      margin-left: 186.96px;
      transform: rotate(-10deg);
      width: 125.35px;
      @media (max-width: 1284px) {
        margin-left: unset;
        padding-left: 70px;
      }
    }
  }
}

.hirek-section {
  background: $vilagos;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);

  .react-multiple-carousel__arrow--right {
    background: transparent !important;
    top: 22.5%;
    right: 20px;
  }

  .react-multiple-carousel__arrow--left {
    background: transparent !important;
    top: 22.5%;
    left: 20px;
  }

  .react-multi-carousel-list {
    overflow-x: hidden !important;
    overflow-y: visible !important;
    padding-bottom: 54.39px;
    margin-bottom: -54.39px;
    margin-left: -10px;
    margin-right: -10px;
  }

  @media (max-width: 810.98px) {
    height: unset;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (min-width: 811px) and (max-width: 1008px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hirek-div {
    @media (max-width: 810.98px) {
      padding-left: 20px;
    }
    .hirek-title {
      margin-bottom: var(--within-section-padding);

      span {
        color: $sotet;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
      }
      @media (max-width: 810.98px) {
        padding-left: 10px;
        span {
          font-size: var(--font-large);
          line-height: var(--line-height-large);
        }
      }
    }

    .hirek-row {
      margin-left: -12px !important;
      margin-right: -12px !important;
      margin-bottom: var(--within-section-padding);

      @media (max-width: 810.98px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      .hirek-col {
        .hirekfooldal-card-div {
          width: 100%;
          height: 331px;
          background: $feher;
          box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.08);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          cursor: pointer;

          @media (max-width: 991px) {
            height: unset;
          }

          .image-div {
            flex-shrink: 0;
            //margin-right: 24px;
            img {
              width: 100%;
            }
            &:hover {
              filter: contrast(50%);
            }
          }
          .hirefooldal-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            padding: 34px;
            height: 100%;

            .title-div {
              height: calc(var(--line-height-medium) * 2);
              color: $sotet;
              font-size: var(--font-medium);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-medium);

              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .text-div {
              margin-top: 20px;
              color: $szoveg;
              font-size: var(--font-small);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-small);
            }
          }
        }
      }
    }
    .loadmore-div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 810.98px) {
        padding-right: 10px;
      }
      button {
        z-index: 100;
        span {
          text-transform: initial;
        }
        &:hover {
          background: $kiemelt;
          transition: 0.6s ease-in-out;
        }
      }
      svg {
        margin-top: -4px;
        margin-left: 4px;
      }
    }
  }
}

.tbkartya-section {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);

  .tbkartya-div {
    width: 100%;
    background-image: url("../../media/images/image 24.png");
    background-size: auto 100%;

    background-repeat: no-repeat;
    background-position: top left;
    height: 296px;
    background-color: #061d51;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 811px) {
      background-size: cover;
      background-position: 100% center;
      height: 211px;
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 40px);
    }

    .info-div {
      max-width: 428px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 24px;
      .text {
        color: $feher;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
      }
      button {
        margin-top: 20.8px;
      }
      @media (max-width: 811px) {
        padding: 0 20px;
        gap: 20px;
        align-items: flex-start;
        min-height: 160px;
        width: min-content;
        .text {
          color: $feher;
          font-size: var(--font-large);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-large);
        }
        button {
          margin-top: 0;
          margin-bottom: 0;
          padding: 10px 24px;
        }
      }
    }
  }
}

.helyszin-section {
  .helyszin-div {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);

    width: 100%;
    position: relative;
    background-image: url("../../media/images/Frame 200.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #061d51;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 811px) {
      background-size: 100% 100%;
    }
    .image-div {
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../../media/images/AGORA 5.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 100%;
      width: 45%;
      @media (max-width: 1180px) {
        height: 650px;
      }
      @media (max-width: 810.98px) {
        display: none;
      }
    }
    .info-div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 0;
      @media (max-width: 1259px) {
        padding-left: 20px;
      }
      p {
        color: $feher;
        max-width: 504px;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
        margin-bottom: var(--within-section-padding);

        @media (max-width: 1180px) {
          max-width: 500px;
        }
      }

      .helyszin-text {
        color: $vilagos;
        max-width: 504px;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-bottom: 30px;

        @media (max-width: 1180px) {
          max-width: 500px;
          margin-bottom: 20px;
        }

        @media (max-width: 960px) {
          max-width: 400px;
        }
      }

      .button-text {
        color: $vilagos;
        max-width: 504px;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-top: 38px;
      }

      .list-text {
        color: $vilagos;
        max-width: 504px;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-bottom: 10px;
      }

      button {
        margin-top: 26px;
      }

      @media (max-width: 811px) {
        padding-left: 20px;
        padding-right: 20px;
        justify-content: flex-start;
        min-height: 160px;
        p {
          color: $feher;
          max-width: 100%;
          font-size: var(--font-large);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-large);
          margin-bottom: 20px;
        }
        .helyszin-text {
          color: $vilagos;
          max-width: 100%;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-normal);
          margin-bottom: 30px;
        }
        .button-text {
          color: $vilagos;
          max-width: 100%;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-normal);
          margin-top: 38px;
        }
        .list-text {
          color: $vilagos;
          max-width: 100%;
          width: 100%;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-normal);
          margin-bottom: 10px;
          img {
            width: 10.99px;
            height: 18.8px;
            margin-right: 10px;
          }
        }
        button {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.hirlevel-section {
  background: #e5e9f4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);

  @media (max-width: 811px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hirlevel-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1008px;
    flex-shrink: 0;
    background: linear-gradient(
        0deg,
        rgba(33, 21, 81, 0.9) 0%,
        rgba(33, 21, 81, 0.9) 100%
      ),
      url("../../media/images/agora.png"), lightgray;
    background-repeat: no-repeat;
    background-size: 101.717% 329.409%;
    background-position: left -90px;
    box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.16);
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);

    @media (max-width: 811px) {
      width: 100%;
    }

    p {
      color: $feher;
      text-align: center;
      font-size: var(--font-large);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-large);
      margin-bottom: 15.6px;
    }
    .hirlevel-text {
      color: $feher;
      text-align: center;
      font-size: var(--font-normal);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-normal);
      margin-bottom: 22.8px;
      @media (max-width: 811px) {
        max-width: 360px;
        overflow-wrap: break-word;
      }
    }
    @media (max-width: 810.98px) {
      p {
        color: $feher;
        text-align: center;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
        margin-bottom: 19px;
      }
      .hirlevel-text {
        color: $feher;
        text-align: center;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-bottom: 28px;
      }
    }
  }
}

.video-section {
  flex-shrink: 0;
  background: $vilagos;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);

  .container {
    @media (min-width: 811px) and (max-width: 1008px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .video-div {
    display: flex;
    align-items: center;

    iframe {
      max-width: 100%;
    }

    @media (max-width: 811px) {
      flex-direction: column;
      height: unset;
    }
    .tartalom-div {
      padding-left: 58.31px;
      max-width: 533px;

      @media (max-width: 811px) {
        padding-bottom: 30px;
      }
      @media (max-width: 1248px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      p {
        color: $elsodleges;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);

        @media (max-width: 1248px) {
          font-size: var(--font-large);
          height: unset;
        }

        @media (max-width: 811px) {
          font-size: var(--font-large);
        }
      }

      span {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        margin-top: 22.4px;
        @media (max-width: 811px) {
          margin-top: 10px;
        }
      }
    }
  }
}

.agoraapp-section {
  flex-shrink: 0;
  background: $vilagos;

  .agoraap-container {
    background-image: url("../../media/images/telefon 1.png");
    background-size: cover !important;
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);

    @media (min-width: 811px) and (max-width: 1008px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    @media (max-width: 811px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    @media (max-width: 500px) {
      background-image: url("../../media/images/telefon respo.png");
      background-size: 100% auto;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    background-repeat: no-repeat;
    background-position: top left;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .mobile-image {
      position: absolute;
      right: 7%;
      background-image: url("../../media/images/telefon-web-05-03 1.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top left;
      width: 536px;
      height: 100%;

      @media (max-width: 1284px) {
        right: 0;
        width: 490px;
        height: 115%;
      }
      @media (max-width: 966px) {
        right: 0;
        width: 450px;
        height: 110%;
      }
    }

    .agoraapp-div {
      max-width: 1008px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      p {
        color: $elsodleges;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
        max-width: 511px;
        @media (max-width: 1284px) {
          max-width: 480px;
        }
        @media (max-width: 960px) {
          max-width: 400px;
        }
      }
      .text {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        max-width: 600px;
        height: 72.8px;

        @media (max-width: 1284px) {
          max-width: 520px;
          height: 80px;
        }
        @media (max-width: 960px) {
          max-width: 380px;
        }
      }

      .list-text {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 400;
        line-height: var(--line-height-normal);
        margin-bottom: 21px;

        img {
          margin-right: 10px;
          width: 25.6px;
        }

        @media (max-width: 1284px) {
          margin-bottom: 15px;
          img {
            margin-right: 5px;
            width: 25px;
          }
        }
      }

      .store-div {
        display: flex;
        flex-direction: row;
        gap: 18px;
        margin-top: 12px;

        .store-image {
          width: 190px;
          height: 54px;
        }
      }
    }
    .agoraapp-div-respo {
      max-width: 1260px;
      height: 495px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      p {
        color: $elsodleges;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
        max-width: 511px;
        height: 109px;
      }
      .text {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        max-width: 404px;
        height: 108px;
      }
      .list-text {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: normal;
        font-weight: 400;
        line-height: var(--line-height-normal);
        margin-bottom: 10px;
        img {
          margin-right: 10px;
        }
      }

      .store-div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 12px;

        .store-image {
          width: 190px;
          height: 54px;
        }
      }
    }
    @media (max-width: 810.98px) {
      .agoraapp-div-respo {
        max-width: 1260px;
        height: unset;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        p {
          color: $elsodleges;
          font-size: var(--font-large);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-large);
          max-width: 450px;
          height: unset;
        }
        .text {
          color: $szoveg;
          font-size: var(--font-normal);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-normal);
          max-width: 404px;
          height: unset;
          margin-bottom: 18px;
        }
      }
    }
  }
}

.tamogatasok-section {
  padding: 28.8px 0;
  position: relative;
  background: #e5e9f4;
  z-index: 2500;
  .tamogatasok-container {
    .tamogatasok-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .eu-image {
        height: 66.4px;
        width: auto;
        margin-right: 216px;
      }
      .nka-image {
        height: 83px;
        width: auto;
      }
      @media (max-width: 811px) {
        justify-content: space-around;
        .eu-image {
          height: 40px;
          width: auto;
          margin-right: 0;
        }
        .nka-image {
          height: 53px;
          width: auto;
        }
      }
    }
  }
}

.search-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #f8faff;
  .column {
    float: left;
    margin: 61px 0 0 0;
  }

  .left {
    width: 25%;
    margin-top: 210px;
    .navbar {
      background-color: transparent;
      box-shadow: none;
      a {
        margin-bottom: 15px;
        padding: 0 !important;
        color: $szoveg;
        text-align: left;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        position: relative;
        &:hover {
          color: $kiemelt;
        }
        &.active {
          color: $kiemelt;
          font-weight: 600 !important;
        }
      }
    }
  }
  .right {
    width: 75%;
    border-left: 1px solid #d4d4d4;
    padding-left: 12px;
    .navigate-div {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      button {
        color: $szoveg;
        font-size: var(--font-normal);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-normal);
        cursor: pointer;
        background: transparent;
        border: none;
        box-shadow: none;
        &:hover {
          color: $kiemelt;
        }
      }
      img {
        margin: 0 10px;
      }
    }
    .search-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      z-index: 1050;
      padding: 0 0 0 12px;
      .search-tag {
        position: relative;
        width: 100%;
        max-width: 1256px;
        display: flex;
        align-items: center;
        .form-outline {
          width: 100%;
          height: 53px;
          .form-control {
            min-height: 53px;
            padding-top: 0.32rem;
            padding-bottom: 0.32rem;
            padding-left: 30px;
            padding-right: 0.75rem;
            border: 1px solid #d4d4d4;
            border-radius: 0;
            background-color: #ffffff;
            transition: all 0.2s linear;
            color: #8b8b8b;
            font-size: var(--font-normal);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
          }
          .form-control ~ .form-label {
            position: absolute;
            top: 50%;
            left: 0;
            padding-top: 0;
            padding-left: 30px;
            transform: translateY(-50%);
            margin-bottom: 0;
            color: #8b8b8b;
            text-align: center;
            font-size: var(--font-normal);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-normal);
          }
          .form-control:focus ~ .form-label {
            display: none;
          }
          .form-control.active ~ .form-label {
            display: none;
          }
          .form-control ~ .form-notch {
            display: none !important;
          }
        }
        .datum-search-button {
          right: 38px;
          position: absolute;
          img {
            width: 22px;
            height: 22px;
            flex-shrink: 0;
          }
        }
      }
      .close-tag {
        button {
          margin-top: 76px;
          background-color: transparent;
          border: none;
          box-shadow: none;
        }
      }
    }

    .program-title {
      padding-top: 38px;
      padding-left: 12px;
      span {
        color: $sotet;
        font-size: var(--font-large);
        font-style: italic;
        font-weight: 600 !important;
        line-height: var(--line-height-large);
      }
    }
    .programok-row {
      margin-right: -12px !important;
      padding-top: 5px;

      .programok-col {
        padding: 24px 12px 0 12px;
        .search-card-div {
          width: 100%;
          height: 307px;
          background: $feher;
          box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.08);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          cursor: pointer;
          .image-div {
            height: 166.879px;
            flex-shrink: 0;
            //margin-right: 24px;
            img {
              width: 100%;
            }
          }
          .datum-div {
            padding-left: 25px;
            span {
              color: $szoveg;
            }
          }
          .searchcard-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            padding: 10px 25px 25px 25px;
            height: 100%;
            .title-div {
              height: 84px;
              overflow: hidden;
              color: $sotet;
              font-size: var(--font-medium);
              font-style: italic;
              font-weight: 600 !important;
              line-height: var(--line-height-medium);
            }
            .text-div {
              margin-top: 10px;
              color: $szoveg;
              font-size: var(--font-small);
              font-style: normal;
              font-weight: 200 !important;
              line-height: var(--line-height-small);
            }
          }
        }
        .aloldal-card-div {
          display: flex;
          height: 177px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 23px;
          cursor: pointer;
          border: 2px solid #d4d4d4;
          background: #fff;
          padding: 0 10px;
          &.left {
            margin-left: 10px;
          }
          &.right {
            margin-right: 10px;
          }
          p {
            margin: 0;
            color: #000;
            text-align: center;
            font-size: var(--font-medium);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-medium);
          }
          &:hover {
            p {
              color: #f0b323;
            }
          }
        }
      }
    }
    .paginator {
      width: 100%;
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          list-style: none;
          a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            width: 34px;
            font-size: var(--font-small);
            color: #5b5f5d;
            text-decoration: none;
            background-color: transparent;
            border: 1px solid #dee2e6;
            &:hover {
              background-color: #e9ecef;
            }
            &.active {
              background-color: #006134;
              color: #ffffff;
            }
          }
        }
      }
    }
    .loadmore-div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 12px;
      margin-bottom: 85px;
      button {
        margin-top: 15px;
        span {
          text-transform: initial;
        }
        &:hover {
          background: $kiemelt;
          transition: 0.6s ease-in-out;
        }
      }
      svg {
        margin-top: -4px;
        margin-left: 4px;
      }
    }
  }
}

.search-section-respo {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f8faff;

  .navbar {
    background-color: transparent;
    box-shadow: none;
    padding: 30px 20px 0 20px;
    a {
      margin-bottom: 15px;
      padding: 0 !important;
      color: $szoveg;
      text-align: left;
      font-size: var(--font-normal);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-normal);
      position: relative;
      &:hover {
        color: $kiemelt;
      }
      &.active {
        color: $kiemelt;
        font-weight: 600 !important;
      }
    }
  }
  .navigate-div {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    padding: 40px 20px 0 20px;
    button {
      color: $szoveg;
      font-size: var(--font-small);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-small);
      cursor: pointer;
      background: transparent;
      border: none;
      box-shadow: none;
      &:hover {
        color: $kiemelt;
      }
    }
    img {
      margin: 0 10px;
    }
  }
  .search-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    z-index: 1050;

    .search-tag {
      position: relative;
      width: 100%;
      max-width: 1256px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      .form-outline {
        width: 100%;
        height: 53px;
        .form-control {
          min-height: 53px;
          padding-top: 0.32rem;
          padding-bottom: 0.32rem;
          padding-left: 30px;
          padding-right: 0.75rem;
          border: 1px solid #d4d4d4;
          border-radius: 0;
          background-color: #ffffff;
          transition: all 0.2s linear;
          color: #8b8b8b;
          font-size: var(--font-normal);
          font-style: normal;
          font-weight: 200 !important;
          line-height: var(--line-height-normal);
        }
        .form-control ~ .form-label {
          position: absolute;
          top: 50%;
          left: 0;
          padding-top: 0;
          padding-left: 30px;
          transform: translateY(-50%);
          margin-bottom: 0;
          color: #8b8b8b;
          text-align: center;
          font-size: var(--font-normal);
          font-style: normal;
          font-weight: 200 !important;
          line-height: var(--line-height-normal);
        }
        .form-control:focus ~ .form-label {
          display: none;
        }
        .form-control.active ~ .form-label {
          display: none;
        }
        .form-control ~ .form-notch {
          display: none !important;
        }
      }
      .datum-search-button {
        right: 38px;
        position: absolute;
        img {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
        }
      }
    }
    .close-tag {
      button {
        margin-top: 76px;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }

  .program-title {
    padding: 38px 20px 0 20px;
    span {
      color: $sotet;
      font-size: var(--font-large);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-large);
    }
  }
  .programok-row {
    padding-top: 5px;

    .programok-col {
      padding: 24px 20px 0 20px;
      .search-card-div {
        width: 100%;
        //height: 307px;
        background: $feher;
        box-shadow: 8px 16px 40px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: pointer;
        .image-div {
          //height: 166.879px;
          flex-shrink: 0;
          //margin-right: 24px;
          img {
            width: 100%;
          }
        }
        .datum-div {
          padding-left: 25px;
          padding-top: 15px;
          span {
            color: $szoveg;
          }
        }
        .searchcard-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          padding: 10px 25px 25px 25px;
          //height: 100%;
          .title-div {
            height: 84px;
            overflow: hidden;
            color: $sotet;
            font-size: var(--font-normal);
            font-style: italic;
            font-weight: 600 !important;
            line-height: var(--line-height-normal);
          }
          .text-div {
            margin-top: 10px;
            color: $szoveg;
            font-size: var(--font-small);
            font-style: normal;
            font-weight: 200 !important;
            line-height: var(--line-height-small);
          }
        }
      }
      .aloldal-card-div {
        display: flex;
        height: 177px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 23px;
        cursor: pointer;
        border: 2px solid #d4d4d4;
        background: #fff;
        padding: 0 10px;
        &.left {
          margin-left: 10px;
        }
        &.right {
          margin-right: 10px;
        }
        p {
          margin: 0;
          color: #000;
          text-align: center;
          font-size: var(--font-medium);
          font-style: italic;
          font-weight: 600 !important;
          line-height: var(--line-height-medium);
        }
        &:hover {
          p {
            color: #f0b323;
          }
        }
      }
    }
  }
  .paginator {
    width: 100%;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        list-style: none;
        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          width: 34px;
          font-size: var(--font-small);
          color: #5b5f5d;
          text-decoration: none;
          background-color: transparent;
          border: 1px solid #dee2e6;
          &:hover {
            background-color: #e9ecef;
          }
          &.active {
            background-color: #006134;
            color: #ffffff;
          }
        }
      }
    }
  }
  .loadmore-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
    margin-bottom: 85px;
    button {
      margin-top: 15px;
      span {
        text-transform: initial;
      }
      &:hover {
        background: $kiemelt;
        transition: 0.6s ease-in-out;
      }
    }
    svg {
      margin-top: -4px;
      margin-left: 4px;
    }
  }
}

.agora-link {
  background: $vilagos;
  height: 124px;
  flex-shrink: 0;
  display: flex;
  position: relative;
  z-index: 2500;

  .container {
    @media (min-width: 811px) and (max-width: 1008px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .agora-link-div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ava-fekete-logo {
      width: 243px;
    }
    .agora-link-text {
      color: #000;
      text-align: center;
      font-size: var(--font-normal);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-normal);
    }
    .loadmore-div {
      width: 243px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.agora-link-respo {
  background: $vilagos;
  //height: 124px;
  flex-shrink: 0;
  display: flex;
  padding-top: 38px;
  padding-bottom: 38px;
  .agora-link-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ava-fekete-logo {
      width: 243px;
      margin: 10px 0;
    }
    .agora-link-text {
      color: #000;
      text-align: center;
      font-size: var(--font-normal);
      font-style: italic;
      font-weight: 600 !important;
      line-height: var(--line-height-normal);
      margin: 10px 0;
    }
    .loadmore-div {
      width: 243px;
      display: flex;
      justify-content: center;
      margin: 10px 0;
    }
  }
}

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page-container {
  margin: 20px;
  width: 650px;
  height: 700px;
  overflow-y: scroll;
  box-shadow: 0 25px 50px 0 rgba (62, 62, 62, 0.15);
  -webkit-box-shadow: 0 25px 50px 0 rgb(62 62 62 / 15%);
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 500px;
}

.button-container {
  display: flex;
  gap: 8px;
}
